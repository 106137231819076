import App from '../../../App'
import {render, html} from 'lit/html.js'
import {gotoRoute, anchorRoute } from '../../../Router'
import SAuth from '../../../SAuth'
import Validate from '../../../functions/Validate' 
import ThreadAPI from '../../../api/ThreadAPI'
import Toast from '../../../Toast'
import Modal from '../../../functions/Modal'
import Moderate from '../../../functions/Moderate'
import Format from '../../../functions/Format'


//revise "array update" code @ #44 & #157
class ModThreadActionsView {
  init(){    
    document.title = 'Thread Actions'     
    this.threadInit()
  }

  async threadInit(){
    if(!Moderate.myThread.user){  gotoRoute('/mod/checkthread'); return }
    this.render() 
    this.threadUpdate()
  }
  

  threadUpdate(){
    const tTextDiv = document.getElementById('threadText')
    const formattedText = Format.formatParagraphText(Moderate.myThread.threadText, 'textarea')
    tTextDiv.innerHTML = formattedText
  }

  async approveHandler(){
    const mainTab = document.getElementById('main-tab')
    const statusTab = document.getElementById('status-tab')
    mainTab.classList.add('hidden')
    statusTab.innerHTML = '<c-waiting waitSource="updating"></c-waiting>'
    let newThread = {}
    newThread.approved = true
    newThread.moderator = SAuth.currentUser._id
    const threadObject = JSON.stringify(newThread)

    try{
      await ThreadAPI.updateThread(Moderate.modGroup, Moderate.myThread._id, threadObject)
      Toast.show('Thread Approved')
      //revise this code if working
      for (let i = 0; i < Moderate.modThreads.length; i++) {
        if (Moderate.modThreads[i]._id == Moderate.myThread._id) {
          Moderate.modThreads.splice(i, 1);
        }
      }
      gotoRoute('/mod/checkthread')
    }catch(err){
      statusTab.innerHTML = '<c-error errorSource="goback"></c-error>'
      //Toast.show(err, 'error')
    }
  }

  async updateHandler(){
    let newThread = Moderate.myThread
    let updateThread = {}
    let validated = true
    let newTitle = null
    let newText = null
    let changes = false
    const titleInput = document.getElementById("threadTitle")
    const textInput = document.getElementById("threadText")
    if(titleInput.value !== Moderate.myThread.threadTitle ){
      changes = true
      if(titleInput.value == ""){
        Validate.inputWarn(titleInput.id, "*Title required")
        validated = false
      } else {
        const tStatus = Validate.safeCheck(titleInput.value)
        if (tStatus == false){
          Validate.inputWarn(titleInput.id, "*No special characters")
          validated = false
        } else if (titleInput.value.length < 8 || titleInput.value.length > 30 ){
          Validate.inputWarn(titleInput.id, "*Enter 8 to 30 characters")
          validated = false
        } 
      }
      newTitle = titleInput.value
    } 
    if(textInput.value !== Moderate.myThread.threadText ){
      changes = true
      if(textInput.value == ""){
        Validate.inputWarn(textInput.id, "*Title required")
        validated = false
      } else {
        const tStatus = Validate.safeCheck(textInput.value)
        if (tStatus == false){
          Validate.inputWarn(textInput.id, "*No special characters")
          validated = false
        } else if (textInput.value.length < 8 || textInput.value.length > 3000 ){
          Validate.inputWarn(textInput.id, "*Enter 8 to 3000 characters")
          validated = false
        } 
      }
      newText = textInput.value
    } 
    if(changes == false){
      Modal.notice = "No changes have been made"
      Modal.modalNotice()
      return
    } 
    if(validated == false){ return }
    const mainTab = document.getElementById('main-tab')
    const statusTab = document.getElementById('status-tab')
    mainTab.classList.add('hidden')
    statusTab.innerHTML = '<c-waiting waitSource="updating"></c-waiting>'
    if(newTitle !== null){ 
      updateThread.threadTitle = newTitle 
      newThread.threadTitle = newTitle
    }
    if(newText !== null){ 
      updateThread.threadText = JSON.stringify(newText) 
      newThread.threadText = JSON.stringify(newText)  
    }
    updateThread.editMod = SAuth.currentUser._id
    try {
      const threadData = JSON.stringify(updateThread)
      const udStatus = await ThreadAPI.updateThread(Moderate.modGroup, Moderate.myThread._id, threadData)
      if(udStatus == false){
        statusTab.innerHTML = '<c-error errorSource="goback"></c-error>'
      }
      const objIndex = Moderate.modThreads.findIndex((obj => obj._id == Moderate.myThread._id ))
      Moderate.modThreads[objIndex] = newThread
      Moderate.myThread = newThread
      Toast.show('Thread Updated')
      statusTab.innerHTML = ''
      mainTab.classList.remove('hidden')
      this.render()
    } catch(err) {
      console.log(err)
      statusTab.innerHTML = '<c-error errorSource="goback"></c-error>'
    }
  }
  
  
  deleteAlertHandler(e){
    e.preventDefault()
    const mainTab = document.getElementById('main-tab')
    const alertTab = document.getElementById('alert-tab')
    mainTab.classList.add('hidden')
    alertTab.classList.remove('hidden')
  }


  async deleteHandler(){
    const alertTab = document.getElementById('alert-tab')
    const statusTab = document.getElementById('status-tab')
    alertTab.classList.add('hidden')
    statusTab.innerHTML = '<c-waiting waitSource="delete"></c-waiting>' 
    try {
      const status = await ThreadAPI.deleteThread(Moderate.modGroup, Moderate.myThread._id)
      if(status == false){
        statusTab.innerHTML = '<c-error errorSource="goback"></c-error>'
      } else { 
        //check if this code is working (on post array it is not working)
        for (let i = 0; i < Moderate.modThreads.length; i++) {
            if (Moderate.modThreads[i]._id == Moderate.myThread._id) {
              Moderate.modThreads.splice(i, 1);
            }
        }
        gotoRoute('/mod/checkthread')
      }
    }catch(err){
      statusTab.innerHTML = '<c-error errorSource="goback"></c-error>'
    }
  }

  goback(){
    const mainTab = document.getElementById('main-tab')
    const alertTab = document.getElementById('alert-tab')
    mainTab.classList.remove('hidden')
    alertTab.classList.add('hidden')
  }

  render(){
    const template = html`
      
      <div class="main-content">
        <main-app-header pagename="${document.title}"></main-app-header>
 
        <div class="content-panel"> 
          <div id="main-tab">   
          
            <a href="/mod/checkthread" class="forum-button forum-btn primary" @click=${anchorRoute}>Back to Threads
              <img class="back-img" alt="back" src="https://hashtag-metoo.com/images/icons/sw00-back.svg">
            </a>
            
            <div class="h-center">
              <strong>Censor Thread: </strong>
              *Replace censored text with 4 hashtags: ####
              <div class="divide-line"></div>
            </div>

            ${!Moderate.myThread.user? html`
              Waiting for thread... <br>
              <div class="spinner sp-sml"></div> 
            
            `: html`  
              <p class="para">
                ${new Date(Moderate.myThread.createdAt).toLocaleDateString()} - ${Moderate.myThread.user.userName} 
              </p>
                
              <form class="page-form" method="post">

                <div class="input-group">
                  <p id="threadTitle-wrn" class="input-require">*No special characters & max 30 </p>
                  <textarea class="para" rows="2" cols="50" id="threadTitle" @keyup=${Validate.clearWarning.bind(Validate)}>${Moderate.myThread.threadTitle}</textarea>
                </div>
                <div class="input-group">
                  <p id="threadText-wrn" class="input-require">*No special characters & max 3000 </p>
                  <textarea id="threadText" class="para" rows="6" cols="50" id="threadText" @keyup=${Validate.clearWarning.bind(Validate)}></textarea>
                </div>
                
                <div class="two-buttons">
                  <a class="mod-button action" @click=${this.updateHandler.bind(this)}>Update</a>
                  <a class="mod-button warning" @click=${this.deleteAlertHandler}>Delete</a>
                </div>
                <br>
                <div class="v-center">
                  <a class="mod-button primary" @click=${this.approveHandler}>Approve</a>
                </div>
              </form>
            `} 
          </div>

          <div id="status-tab"></div>

          <div id="alert-tab" class="hidden">
            <div class="confirm-wrap h-center">
              <p class="para h-center confirm-space">Are you sure you want to remove this thread?</p>
              <div id="delete-click" class="redirect-button warning confirm-space" @click=${this.deleteHandler.bind(this)}>Delete</div>
              <div id="back-click" class="redirect-button primary confirm-space" @click=${this.goback}>Cancel</div>
            </div>
          </div>
        </div>  
      </div> 
    `

    render(template, App.rootEl)
  }
}

export default new ModThreadActionsView()