import {html} from 'lit/html.js'

const splash = html`
  <div class="sign-content v-center">
    <div class="form-box"> 
      <!-- <div class="spinner sp-lg"></div> -->
      <c-spinner spinnerSize="sp-lg"></c-spinner>
    </div>
  </div>
`
export default splash