import App from '../../App'
import SAuth from '../../SAuth'
import Toast from '../../Toast'
import {gotoRoute, anchorRoute } from '../../Router'

class ForgotApi {

  async forgotLink(userData, fail = false) {
    try{
      const response = await fetch(`${App.apiBase}/sforgot/`, {
        method: 'POST',      
        headers: {"Content-Type" : "application/json", "Origin": App.origin},
        body: userData
      })
      if(!response.ok){
        const err = await response.json()
        if(err) console.log(err)
        Toast.show(`${response.status}: ${err.message}`, 'error')   
        // run fail() functon if set
        if(typeof fail == 'function') fail()
        return false // 
      }
      const mailMessage = await response.json()
      Toast.show(`${mailMessage.message}`)
      return true
    } catch(err) {
      console.log(err)
      Toast.show(err, 'error')
      return false
    }
  }


  async verifyForgotToken(token, fail = false){
    try{
      const response = await fetch(`${App.apiBase}/sforgot/validatetoken`, { 
        method: 'GET', headers: { "Authorization": `Bearer ` + token } 
      })
      if(!response.ok){      
        const err = await response.json()
        if(err) console.log(err)
        Toast.show(`${response.status}: ${err.message}`, 'error')   
        if(typeof fail == 'function') fail()
        return false
      }
      const data = await response.json()
      SAuth.forgotUser = data.user.user._id 
      return true
    } catch(err) {
      console.log(err)
      Toast.show(err, 'error')
      return false
    }
  } 


  async resetPW(userId, userData, fail = false){
    if(!userId || !userData) return false
    try{
      let responseHeader
      responseHeader = {
        method: "PUT",    
        headers: {
          "Content-Type" : "application/json", 
          "Origin": App.origin
        },
        body: userData
      }
      const response = await fetch(`${App.apiBase}/sforgot/${userId}`, responseHeader)
      if(!response.ok){
        const err = await response.json()
        if(err) console.log('not ok' + err)
        Toast.show(`${response.status}: ${err.message}`, 'error')   
        if(typeof fail == 'function') fail()
        return false
      }
      const data = await response.json()
      Toast.show(`Password updated, welcome back ${data.user.userName}`) 
      localStorage.setItem('accessToken', data.accessToken)
      SAuth.currentUser = data.user   
      gotoRoute('/')
      return true
    } catch(err) {
      console.log(err)
      Toast.show(err, 'error')
      return false
    }
  }


}

export default new ForgotApi()