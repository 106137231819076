import App from '../App'
import Toast from '../Toast'

class StaffFeedbackApi {

    async newFeedback (feedbackData, fail = false){
        try{
            const response = await fetch(`${App.apiBase}/stafffeedback/`, {
                method: 'POST',      
                headers: {
                        "Authorization": `Bearer ${localStorage.accessToken}`,
                        "Content-Type" : "application/json", 
                        "Origin": App.origin
                    },
                body: feedbackData
            })
            if(!response.ok){      
              const err = await response.json()
              if(err) console.log(err)  
              Toast.show(`${response.status}: ${err.message}`, 'error')   
              if(typeof fail == 'function') fail()
              return false
            }
            const data = await response.json()
            return true
        } catch(err) {
            console.log(err)
            Toast.show(err, 'error')
            return false
        }
    }


    async getAllFeedback(){
        try{
            const response = await fetch(`${App.apiBase}/stafffeedback`, {
                headers: { "Authorization": `Bearer ${localStorage.accessToken}`}
              })
            if(!response.ok){      
              const err = await response.json()
              if(err) console.log(err)
              Toast.show(`${response.status}: ${err.message}`, 'error')   
              if(typeof fail == 'function') fail()
              return false
            }
            const data = await response.json()
            return data
        } catch(err) {
            console.log(err)
            Toast.show(err, 'error')
            return false
        }
    }


}

export default new StaffFeedbackApi()