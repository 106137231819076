import App from '../../../App'
import {render, html} from 'lit/html.js'
import {anchorRoute } from '../../../Router'
import SAuth from '../../../SAuth'
// import moment from 'moment'
import Country from '../../../functions/country'
import Font from '../../../functions/Font'
// Buttons for /payments /posts /progress (version 2)
//<!-- Member since: ${moment(SAuth.currentUser.createdAt).format('MMMM Do YYYY')} -->

class ProfileView {
  init(){    
    document.title = 'My Profile'  
    this.myCountry = null  
    this.render()  
    Font.initFont()
    Country.initCountry()
    this.myCountry = Country.myCountry
    this.render()  
  }


  render(){
    const template = html`
      <div class="main-content">
        <main-app-header pagename="${document.title}"></main-app-header>

        <div class="content-panel">      

          ${this.myCountry == null ? html` 
              <div class="e-country">
                <div class="spinner sp-sml"></div>
              </div>
            ` : html` 
              <div class="e-country">
                <div class="e-logo">
                  ${this.myCountry.flag == true ? html` 
                    <img class="nav-img" alt="flag" src="https://hashtag-metoo.com/images/flags/flag-${SAuth.currentUser.countryCode}.svg">
                  ` : html` 
                    <img class="nav-img" alt="flag" src="https://hashtag-metoo.com/images/flags/flag-aaa.svg">
                  `}
                </div>
              </div>
            `}


            <div class="h-center">
              <p class="profile-text para">
                <strong>${SAuth.currentUser.firstName} ${SAuth.currentUser.lastName}</strong>
              </p>
              <p class="profile-text para"> 
                Last updated...
                ${new Date(SAuth.currentUser.updatedAt).toDateString()}
              </p>
            </div>

            <br><br>

            
            <a href="/user/editprofile" class="forum-button forum-action-btn action" @click=${anchorRoute}>
              Edit Profile
              <img class="back-img img-padding" alt="back" src="https://hashtag-metoo.com/images/icons/sw04-pencil.svg">
            </a>

            <!-- <div class="divide-line"></div>   -->


        </div> 
      </div> 
    `

    render(template, App.rootEl)
  }
}

export default new ProfileView()