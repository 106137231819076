import App from '../App'
import Toast from '../Toast'


class SubscribeAPI {

    //subscribeData =
      //getting via backend --- emails: [array],  
      //getting via backend --- title:  [threadTitle],  
      //message:  [postText] 
      //group:  
      //threadId:   
      //thisUserEmail: (so we don't send)

//call function at: approve thread (check threads & thread actions)  & approve Post (check posts & post actions)
  async emailSubscribers(subscribeData, fail = false){
    console.log('Subscribe Data... ')
    console.log(subscribeData)
    
    try{
      const response = await fetch(`${App.apiBase}/subscribe/`, {
        method: 'POST',      
        headers: {
          "Authorization": `Bearer ${localStorage.accessToken}`,
          "Content-Type" : "application/json", 
          "Origin": App.origin
        },
        body: subscribeData
      })
      if(!response.ok){
        const err = await response.json()
        if(err) console.log(err)
        Toast.show(`${response.status}: ${err.message}`, 'error')   
        if(typeof fail == 'function') fail()
        return false
      }
      const mailMessage = await response.json()
      //Toast.show(`${mailMessage.message}`)
      console.log(mailMessage.message)
      return true
    } catch(err) {
      console.log(err)
      Toast.show(err, 'error')
      return false
    }

  }

    
}

export default new SubscribeAPI()
