import App from '../../App'
import {render, html} from 'lit/html.js'

class TestView{
  init(){
    document.title = 'TestView'    
    this.render()
  }

  getDates(e){
    e.preventDefault()
    console.log('getting dates')
    const thisYear = new Date().getFullYear()
    console.log('year: ' + thisYear)
    const theDateToday = new Date();
    //const theTimeToday = theDateToday.getTime()
    const theTimeToday = Date.now()
    console.log('theDateToday' + theDateToday)
    console.log('theTimeToday' + theTimeToday)

    //get next month values
    let nextMonth = new Date()
    nextMonth.setDate(nextMonth.getDate() + 31);
    let monthexpirydate = nextMonth.getTime()
    console.log('monthexpirydate: ' + monthexpirydate)

    //adding only to time
    const timeMExp = this.addDaysToExpiryDate(theTimeToday, 31)
    console.log('monthexpirydate VIA TIME: ' + timeMExp)

    //get random date values
    let randomMonth = new Date()
    randomMonth.setDate(randomMonth.getDate() + 10);
    let randomexpirydate = randomMonth.getTime()
    console.log('randomexpirydate: ' + randomexpirydate)


    //next year values
    let nextYear = new Date(); 
    nextYear.setFullYear(nextYear.getFullYear() + 1)
    let yearexpirydate = nextYear.getTime()
    console.log('yearexpirydate: ' + yearexpirydate)

    //days left until month expires
    const monthDaysLeft = this.daysLeftUntilToday(monthexpirydate)
    console.log('daysLeft from month expiry: ' + monthDaysLeft)

    //days left until year expires
    const yearDaysLeft = this.daysLeftUntilToday(yearexpirydate)
    console.log('daysLeft from year expiry: ' + yearDaysLeft) 

    //days left until random expires
    const randomDaysLeft = this.daysLeftUntilToday(randomexpirydate)
    console.log('daysLeft from random expiry: ' + randomDaysLeft)

    const newtimeMExpLefta = this.daysLeftUntilToday(timeMExp)
    console.log('daysLeft from monthexpirydate VIA TIME: ' + newtimeMExpLefta)

    //add month to each
    if(randomexpirydate < theTimeToday) { console.log('Random Expired')} 
    else { console.log('Random Still Valid') }
    if(yearexpirydate < theTimeToday) { console.log('Year Expired')}
    else { console.log('Year Still Valid') }
    if(monthexpirydate < theTimeToday) { console.log('Month Expired')}
    else { console.log('Month Still Valid') }
    //if still valid - then add new days from valid date 
    //else add date from today  ie month or year expiry as above

    const newMExp = this.addDaysToExpiryDate(monthexpirydate, 31)
    const newMYExp = this.addDaysToExpiryDate(yearexpirydate, 365)
    const newRExp = this.addDaysToExpiryDate(randomexpirydate, 10)
    const newtimeMExp = this.addDaysToExpiryDate(timeMExp, 31)

    console.log('NEW daysLeft from random expiry ... ')
    const randomDaysLeftb = this.daysLeftUntilToday(newRExp)
    console.log('daysLeft from random expiry: ' + randomDaysLeftb)
    const yearDaysLeftb = this.daysLeftUntilToday(newMYExp)
    console.log('daysLeft from random expiry: ' + yearDaysLeftb)
    const monthDaysLeftb = this.daysLeftUntilToday(newMExp)
    console.log('daysLeft from random expiry: ' + monthDaysLeftb)

    const newtimeMExpLeft = this.daysLeftUntilToday(newtimeMExp)
    console.log('daysLeft from monthexpirydate VIA TIME: ' + newtimeMExpLeft)

    //days left on current subscription 166706956347931622400000 // 1667069563479
    const mcurrenttimeLeft = this.daysLeftUntilToday(1664738186076)
    console.log('daysLeft - m current subscription: ' + mcurrenttimeLeft)
    const currenttimeLeft = this.daysLeftUntilToday(1698753063282)
    console.log('daysLeft - y+m current subscription: ' + currenttimeLeft)
  }

  addDaysToExpiryDate(expiryDate, days){
    var newExpiry = expiryDate + (86400000*days);
    return newExpiry
  }

  daysLeftUntilToday(time){
    const theDateToday = new Date();
    const theTimeToday = theDateToday.getTime()
    let timeinmilisec  = time - theTimeToday
    let daysLeft = Math.floor(timeinmilisec / 86400000)
    return daysLeft
  }



  render(){
    const template = html`   
    
      <style>
        .radio-holder { margin: auto; width: 32px; height: 30px;}
        
      </style>
      

        Restyle as required - email HTML:

        <div style="text-align:center;width:200px;margin:20px auto;" @click="${this.getDates.bind(this)}">
          <a href="#" style="text-decoration:none;">
            <div style="font-family:sans-serif;background-color:#FFBC00;color:white;border-radius:8px;font-size:1.2em;padding:.4em 1em;">
            Test date operations in console</div>
          </a>
        </div>

        <div style="text-align:center;">
          Div for testing
        </div>
 
    `
    render(template, App.rootEl)
  }
}

export default new TestView()