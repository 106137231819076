import App from '../../../App'
import {render, html} from 'lit/html.js'
import SAuth from '../../../SAuth'
import Validate from '../../../functions/Validate'
import StaffApi from '../../../api/staff/StaffApi'
import Country from '../../../functions/country'
import Modal from '../../../functions/Modal'
import Font from '../../../functions/Font'
import Select from '../../../functions/Select'

// don't use "enter" eventListener if - 2 action btns on a page or text area
class EditProfileView {
  init(){    
    document.title = 'Edit Profile'    
    this.newUser = null
    this.render()  
    Font.initFont()
    this.userInit()
  }

  //here also populate the states for the dropdown
  userInit(){
    Country.initCountry()
    this.setSelect("caseCountry", Country.myCountry.countryName)
    if(SAuth.currentUser.state ){
      if(SAuth.currentUser.state !== ""){
        this.setSelect("caseState", SAuth.currentUser.state)
        this.showSelect("states")
        const myCountry = Country.allCountries.find(field => field.countryName == Country.myCountry.countryName )
        Select.populateOptions('caseState-l', myCountry.state)   
      }
    }
  }

  showSelect(div){
    const thisDiv = document.getElementById(div)
    thisDiv.classList.remove('hidden')
  }

  setSelect(select, value){
    const thisSelect = document.getElementById(select)
    thisSelect.options[0].text = value
    thisSelect.options[0].value = value
  }

  async updateEmailtHandler(){
    //validate email
    const ueinput = document.getElementById("user-email")
    const user = {}
    if(ueinput.value == SAuth.currentUser.email){
      //console.log('email has not changed')
      Validate.inputWarn(ueinput.id, "*Email has not changed")
      return
    }
    if(ueinput.value == ""){
      Validate.inputWarn(ueinput.id, "*Email required")
      return
    } else {
      const ueStatus = Validate.validEmail(ueinput.value)
      if(ueStatus == false){
        Validate.inputWarn(ueinput.id, "*Valid email required")
        return
      } 
    }
    const mainTab = document.getElementById('main-tab')
    const statusTab = document.getElementById('status-tab')
    mainTab.classList.add('hidden')
    statusTab.innerHTML = '<c-waiting waitSource="update"></c-waiting>'
    user.email = ueinput.value
    user.id = SAuth.currentUser._id
    user.oldemail = SAuth.currentUser.email
    if(SAuth.currentUser.forumSubExpiry){  
      user.usergroup = SAuth.currentUser.usergroup
    }
    const newUser = JSON.stringify(user)
    const userEmail = await StaffApi.updateEmailLink(newUser)
    if(userEmail == true){ 
      statusTab.innerHTML = '<c-success successSource="linksent" successInfo="update"></c-success>'
     } else {
      statusTab.innerHTML = '<c-error errorSource="goback"></c-error>'
    }
  }

  updateUserHandler(){
    let validated = true // check all inputs are valid
    let newFields = {}
    const pwinput = document.getElementById("password")
    const cntryinput = document.getElementById("caseCountry")
    const stinput = document.getElementById("caseState")
    const ageinput = document.getElementById("age")
    const newage = Number(ageinput.value) 
    // validate password
    if(!pwinput.value == ""){
      const validPassword = this.validatePassword(pwinput)
      if (validPassword == false) { 
        validated = false 
      } else { 
        newFields.password = pwinput.value
      }
    } 
    // validate country and state 
    if(cntryinput.value !== Country.myCountry.countryName){ 
      let newCountry = Country.allCountries.find(field => field.countryName == cntryinput.value )
      newFields.countryCode = newCountry.countryCode
      if(newCountry.state){
        if(stinput.value.includes('Select')){
          Validate.inputWarn("caseState", "*Required")
          validated = false
        } else {
          newFields.state = stinput.value
        }
      } else {
        if(SAuth.currentUser.state){
          newFields.state = ""
        }
      }
    } else if (stinput.value !== SAuth.currentUser.state) {
      newFields.state = stinput.value
    }
    // validate age
    if(newage !== SAuth.currentUser.age){
      if (isNaN(newage) || newage < 1 || newage > 110) {
        Validate.inputWarn('age', "*Please enter a valid age")
        validated = false
      } else {
        newFields.age = newage
      }
    } 
    let fieldscount = Object.keys(newFields).length
    if (validated == false){ return }
    if (fieldscount >= 1){
      this.newUser = newFields
      this.updateUser()
    } else {
      //console.log('run alert modal no changes made')
      Modal.cmodalNoChange()
    }
  }

  async updateUser(){
    //console.log('updating user')
    const mainTab = document.getElementById('main-tab')
    const statusTab = document.getElementById('status-tab')
    mainTab.classList.add('hidden')
    statusTab.innerHTML = '<c-waiting waitSource="profile"></c-waiting>'
    try {
      const userObject = JSON.stringify(this.newUser);
      const updated = await StaffApi.updateUser(SAuth.currentUser._id, userObject)
      if(updated == false){
        statusTab.innerHTML = '<c-error errorSource="goback"></c-error>'
      } else { 
        statusTab.innerHTML = '<c-success successSource="update" successInfo="profile"></c-success>'
      }
    }catch(err){
      console.log('Problem updating profile')
      console.log(err)
      statusTab.innerHTML = '<c-error errorSource="goback"></c-error>'
    }
  }


  validatePassword(pwinput){
    let validated = true
    const pwStatus = Validate.passwordCheck(pwinput)
    if (pwinput.value.length < 6 || pwinput.value.length > 20 ){
      Validate.inputWarn(pwinput.id, "*Enter 6 to 20 characters")
      validated = false
    } else if (pwStatus == false){
      Validate.inputWarn(pwinput.id, "*Only letters, numbers and !@#$%^&* allowed")
      validated = false
    }
    if(validated == true){ 
      return true 
    } else { return false }
  }

  render(){
    const template = html`
      <div class="main-content">
        <main-app-header pagename="${document.title}"></main-app-header>
        <div class="content-panel">  
          <div id="main-tab">
          
            <h2>Change email</h2>
            <div class="input-group">
              <p id="user-email-wrn" class="input-require">*Need a height</p>
              <input class="para" type="text" id="user-email" value="${SAuth.currentUser.email}" @keyup=${Validate.clearWarning.bind(Validate)}  required>
            </div> 
            <div class="redirect-button forum-action-btn action" @click="${this.updateEmailtHandler}">Send Verification Link</div>
            
            <div class="divide-line"></div>
            
            <h2>User fields</h2>
              <div class="input-group">
                <label class="para label">Password</label>
                <p id="password-wrn" class="input-require">*Need a height</p>
                <input id="password" class="para" type="password" placeholder="New Password" @keyup=${Validate.clearWarning.bind(Validate)} maxlength="20">
              </div>

    <!-- caseCountry -->
                <div class="input-group">
                  <label class="para label">Country</label>
                  <p id="caseCountry-wrn" class="input-require">* Selection required</p>
                  <div class="msc-wrap" @click="${Select.mcsOpenMenu.bind(Select)}">
                    <select title="Country" id="caseCountry" class="para msc-input" type="text" name="caseCountry" @mousedown="${Select.preventSelect}" placeholder="My Country">
                      <option disabled selected value="My Country">My Country</option>
                    </select>
                  </div>
                  <div class="msc-wrap hidden">
                    <div class="msc-list-box">
                      <ul id="caseCountry-l" class="msc-ul para">
                      ${Country.priority.map(country => html `
                        <li><p @click="${Select.mcsSelectOption.bind(Select)}">${country.countryName}</p></li>
                        `)}
                        <div class="priority"></div>
                      ${Country.allCountries.map(country => html `
                          <li><p @click="${Select.mcsSelectOption.bind(Select)}">${country.countryName}</p></li>
                        `)}
                      </ul> 
                    </div>
                  </div>
                </div>

  <!-- CONDITION 3A2) if country has states .... -->
    <!-- caseState -->
                <div id="states" class="input-group hidden">
                  <label class="para label">State</label>
                  <p id="caseState-wrn" class="input-require">* Selection required</p>
                  <div class="msc-wrap" @click="${Select.mcsOpenMenu.bind(Select)}">
                    <select title="State" id="caseState" class="para msc-input" type="text" name="caseState" @mousedown="${Select.preventSelect}">
                      <option disabled selected value="Select">Select state ...</option>
                    </select>
                  </div>
                  <div class="msc-wrap hidden">
                    <div class="msc-list-box">
                      <ul id="caseState-l" class="msc-ul para">
  
                      </ul> 
                    </div>
                  </div>
                </div>

              <!-- <div class="input-group">
                <label class="para label">Age</label>
                <p id="age-wrn" class="input-require">*Need a height</p>
                <input class="para input-number-year" type="number" id="age" max="110" min="0" value="${SAuth.currentUser.age}" placeholder="Age" @keyup=${Validate.clearWarning.bind(Validate)}>
              </div> -->

              <div class="redirect-button forum-action-btn action" @click="${this.updateUserHandler.bind(this)}">Update Profile</div>
         
          </div>
          <div id="status-tab"></div>
        </div> 
      </div> 
    `

    render(template, App.rootEl)
  }
}

export default new EditProfileView()