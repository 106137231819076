import App from './App.js'
import splash from './views/partials/splash'
import {html, render } from 'lit/html.js'
import Element from './functions/Element'

// components (custom web components)  
import './components/main-app-header'
import './components/feedback-widget'

import './components/forum/forum-thread'
import './components/forum/forum-post' 
import './components/forum/forum-btn' 

import './components/map/mod-thread'
import './components/map/mod-post'

//STATUS 
import './components/status/c-spinner'
import './components/status/c-error'
import './components/status/c-success'
import './components/status/c-waiting'
import './components/status/mi-notice'

//MODALS
import './components/modals/main-modal'
import './components/modals/confirm-modal'
import './components/modals/cmi-submit'

// styles
import './scss/master.scss'

// app.init
Element.removeAllChildren(App.rootEl)
render(splash, App.rootEl)

document.addEventListener('DOMContentLoaded', () => {
  App.init()
})

window.addEventListener("resize", () => {
  App.resizeListener()
})


// all imports available to all other pages