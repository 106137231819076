import App from '../../App'
import {render, html} from 'lit/html.js'
import {navClick } from '../../Router'
import Font from '../../functions/Font'


class ModPolicy {
  init(){    
    document.title = 'Forum Policy'    
    this.render() 
    Font.initFont()   
  }
  
  render(){
    const template = html`
      <div class="main-content">
        <main-app-header pagename="${document.title}"></main-app-header>
        <feedback-widget class="feedback-widget"></feedback-widget>
        <div class="content-panel">  

        <p class="app-info para">Moderate and censor posts which oppose forum rules.</p>
        <p class="app-info para">Delete any obscene posts.</p>

        <ul class="app-info para">
          <li>No swearing, insults or blaming</li>
          <li>No naming people (ie predators)</li>
          <li>No sharing personal information</li>
          <li>No preaching. Only "God" permitted</li>
        </ul>
        <p class="app-info para">*UPDATE BEFORE APPROVING.</p>

        <br><br>





         
        </div> 
        
      </div>
    `

    render(template, App.rootEl)
  }
}

export default new ModPolicy()