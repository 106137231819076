import SAuth from '../SAuth';


class Font {

    constructor(){

      this.warnItems = []
      this.myDiv = document.getElementById('contentpanelid')

    }  

      //------------------currently not used -------------------------

  // scrollToTop(){
  //   this.myDiv.scrollTop = 0;
  // }

  //------------------currently not used -------------------------

  // scrollTopSmooth(){
  //   this.myDiv.scrollTo( {top: 0, behavior: 'smooth'})
  // }

  //------------------currently not used -------------------------
  // scrollTopWarning(){
  //   if(this.warnItems.length > 0){
  //     const topWarn = document.getElementById(this.warnItems[0] + '-wrn') 
  //     topWarn.scrollIntoView({
  //       top: -20,
  //       behavior: 'smooth'
  //     })
  //   }
  // }

  
  }
  
  export default new Font()