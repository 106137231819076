import App from '../../../App'
import {render, html} from 'lit/html.js'
import {gotoRoute, anchorRoute } from '../../../Router'
import SAuth from '../../../SAuth'
import Validate from '../../../functions/Validate'
import ForgotApi from '../../../api/staff/SForgotApi'
import {myLogo} from '../../../data/Images'
import Font from '../../../functions/Font'

class ResetView {
  init(){    
    document.title = 'Reset'    
    this.userData = {}
    this.render()  
    this.initReset()
    Font.initFont() 
    //need to remove the eventlistener (this event listener isn't removed)
    document.addEventListener('keyup', (e) => {
      this.enterBtn(e)
    })
  }

  enterBtn(e){
    const mainTab = document.getElementById('main-tab')
    if (e.key === 'Enter' && !mainTab.classList.contains('hidden')) {
      this.upddatePWHandler()
    }
  }

  initReset(){
    if(SAuth.forgotUser == null){
      console.log('no token... redirecting...')
      gotoRoute('/signin')
    }
  }

  async upddatePWHandler(){
    const mainTab = document.getElementById('main-tab')
    const statusTab = document.getElementById('status-tab')
    const pwstatus = this.validatePassword()
    if(pwstatus == false){ return }
    else {
      mainTab.classList.add('hidden')
      statusTab.innerHTML = '<c-waiting waitSource="reset"></c-waiting>'
      try {
        const userObject = JSON.stringify(this.userData);
        const updated = await ForgotApi.resetPW(SAuth.forgotUser, userObject)
        if(updated == false){
          statusTab.innerHTML = '<c-error errorSource="validating" errorInfo="forgot"></c-error>'
        } else { return }
      }catch(err){
        console.log('Problem resetting new password')
        statusTab.innerHTML = '<c-error errorSource="validating" errorInfo="forgot"></c-error>'
      }
    }   
  }

  validatePassword(){
        let validated = true
        const pwinput = document.getElementById("password")
        const pwbinput = document.getElementById("passwordb")
        if(pwinput.value == ""){
          Validate.inputWarn(pwinput.id, "*Required")
          validated = false
        } else {
          const pwStatus = Validate.passwordCheck(pwinput)
          if (pwinput.value.length < 6 || pwinput.value.length > 20 ){
            Validate.inputWarn(pwinput.id, "*Enter 6 to 20 characters")
            validated = false
          } else if (pwStatus == false){
            Validate.inputWarn(pwinput.id, "*Only letters, numbers and !@#$%^&* allowed")
            validated = false
          }
        }
        if(pwbinput.value == ""){
          Validate.inputWarn(pwbinput.id, "*Confirm password Required")
          validated = false
        } else if(pwbinput.value !== pwinput.value){
          Validate.inputWarn(pwbinput.id, "*Password needs to match")
          validated = false
        }
        if(validated == true){ 
          this.userData.password = pwinput.value
          return true 
        } 
         else { return false }
  }

  
  render(){
    const template = html`
      
      <div class="sign-content v-center">
        <div class="form-box"> 
          <div id="main-tab">

            <div class="signinup-logo-mobile" alt="logo ${App.name}">${myLogo}</div>
            <div class="signinup-logo-mobile" alt="${App.name}">
          
            </div>
            <!-- <img class="signinup-logo-mobile" alt="${App.name}" class="logo-home" src="https://hashtag-metoo.com/images/icons/1-logo-${App.appcode}.svg" >
            <img class="signinup-logo-mobile" alt="${App.name}" src="https://hashtag-metoo.com/images/icons/2-apptext-${App.appcode}.svg" ><br> -->
            <p class="form-name">Reset Password</p>      
          

            <div class="input-group">
                <label class="para label">Password:</label>
                <p id="password-wrn" class="input-require">* Password required</p>
                <input id="password" class="para" type="password" placeholder="Password" @keyup=${Validate.clearWarning.bind(Validate)} maxlength="20">
              </div>

              <div class="input-group">
                <label class="para label">Confirm password:</label>
                <p id="passwordb-wrn" class="input-require">* Password needs to match</p>
                <input id="passwordb" class="para" type="password" placeholder="Password" @keyup=${Validate.clearWarning.bind(Validate)} maxlength="20">
              </div>

              
              <button class="submit-btn" submit @click=${this.upddatePWHandler.bind(this)}>Update Password</button>

            <a href="/" class="home-button primary" @click=${anchorRoute}>Home</a>
          </div>
          <div id="status-tab"></div>
        </div>
      </div> 
    `

    render(template, App.rootEl)
  }
}

export default new ResetView()