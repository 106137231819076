export const myLogo = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52.736 48.2684"><defs><style>.cls-1{fill:#00afc6;}.cls-2{fill:#fff;}</style></defs><g><path class="cls-1" d="M26.368,48.2684A33.3754,33.3754,0,0,1,37.1117,36.7706C44.1406,32.3956,55.46,25.3516,52.14,10.1431,49.9941.3089,34.5683-5.69,26.368,8.025,18.9487-5.69,2.7419.3089.5956,10.1431c-3.3192,15.2085,8,22.2525,15.0286,26.6275A33.3763,33.3763,0,0,1,26.368,48.2684Z"/><path class="cls-2" d="M33.7783,19.4558,32.446,24.1384h2.9471q2.1,0,2.1,2.2206a2.5877,2.5877,0,0,1-.5455,1.7559,1.9487,1.9487,0,0,1-1.5544.6264H31.1136l-.7663,2.5827a3.4513,3.4513,0,0,1-1.1912,1.797,2.8609,2.8609,0,0,1-1.756.6263,2.0969,2.0969,0,0,1-1.453-.5455,1.93,1.93,0,0,1-.6059-1.5134,3.6765,3.6765,0,0,1,.1207-.8882l.6059-2.0589H21.3442l-.7676,2.5827a3.4491,3.4491,0,0,1-1.19,1.797,2.8665,2.8665,0,0,1-1.7571.6263,2.0992,2.0992,0,0,1-1.4531-.5455,1.9338,1.9338,0,0,1-.6058-1.5134,3.6771,3.6771,0,0,1,.1219-.8882l.6046-2.0589H15.1265a2.0113,2.0113,0,0,1-1.5738-.6264,2.5223,2.5223,0,0,1-.566-1.7559,2.2429,2.2429,0,0,1,.566-1.6558,2.1277,2.1277,0,0,1,1.5738-.5648H17.63l1.3734-4.6826H16.7412a2.0111,2.0111,0,0,1-1.5737-.6264,2.522,2.522,0,0,1-.566-1.7559,2.2424,2.2424,0,0,1,.566-1.6558,2.1275,2.1275,0,0,1,1.5737-.5648h3.594l.7664-2.6648a3.0527,3.0527,0,0,1,1.1513-1.7559,2.9907,2.9907,0,0,1,1.756-.5854,2.3958,2.3958,0,0,1,1.5544.5045,1.65,1.65,0,0,1,.6252,1.3529,2.2882,2.2882,0,0,1-.1207.7265l-.6867,2.4222h4.7236l.7675-2.6648a3.0514,3.0514,0,0,1,1.15-1.7559,2.99,2.99,0,0,1,1.7559-.5854,2.3959,2.3959,0,0,1,1.5545.5045,1.6478,1.6478,0,0,1,.6263,1.3529,2.2873,2.2873,0,0,1-.1219.7265l-.6855,2.4222h1.8562q2.1,0,2.1,2.2206a2.5878,2.5878,0,0,1-.5455,1.7559,1.9488,1.9488,0,0,1-1.5544.6264Zm-5.006,0H24.0089l-1.3324,4.6826H27.4Z"/></g></svg>'
export const myClose = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.2719 11.2597"><defs><style>.cls-1{fill:#00afc6;}</style></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_6" data-name="Layer 6"><path class="cls-1" d="M7.426,5.6238l3.4752-3.4752a1.2659,1.2659,0,0,0,0-1.79,1.2962,1.2962,0,0,0-1.79,0L5.6359,3.834,2.1607.3585a1.2962,1.2962,0,0,0-1.79,0,1.2669,1.2669,0,0,0,0,1.79L3.8461,5.6238.3709,9.0993a1.265,1.265,0,0,0,0,1.79,1.2962,1.2962,0,0,0,1.79,0L5.6359,7.4136l3.4755,3.4755a1.2657,1.2657,0,1,0,1.79-1.79Z"/></g></g></svg>'

export const myBackArw = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.4571 16.3515"><defs><style>.cls-1{fill:#00afc6;}</style></defs><g><path class="cls-1" d="M13.1374,1.5969A8.19,8.19,0,0,0,2.449,2.4454l-.0763-.6642a1.19,1.19,0,0,0-2.3648.2712l.5015,4.37a.6647.6647,0,0,0,.7363.5846L5.761,6.4883A1.19,1.19,0,1,0,5.49,4.1235L3.982,4.2966a5.7969,5.7969,0,1,1,4.62,9.6766,1.19,1.19,0,0,0,.0666,2.3783c.0225,0,.0449-.0008.0682-.0019a8.1844,8.1844,0,0,0,4.401-14.7527Z"/></g></svg>'






//white icon images for home page badgew checkw pencilw
export const badgew = '<svg class="nav-img" style="height:100%;" alt="Badge icon" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 77.988 100" enable-background="new 0 0 77.988 100" xml:space="preserve"><g><path fill="#FFFFFF" d="M75.195,13.828l-1.278,0.04c-0.106,0-10.272,0.277-17.659-2.64c-7.521-2.971-15.06-9.69-15.132-9.757l-0.813-0.731c-1.155-1.037-2.92-0.975-3.996,0.143l-0.762,0.793c-0.056,0.056-5.537,5.716-12.195,8.76c-5.616,2.564-15.866,3.136-19.341,3.138H2.875c-1.597,0-2.886,1.3-2.875,2.895l0.011,1.143c0.011,1.566,0.37,38.5,6.815,51.894c6.507,13.524,28.398,28.911,29.323,29.562l0.627,0.426c0.953,0.672,2.214,0.694,3.195,0.067l0.639-0.415c2.606-1.67,25.562-16.727,29.961-30.653c4.164-13.21,7.331-48.759,7.465-50.267l0.106-1.275C78.294,15.234,76.916,13.774,75.195,13.828z M29.569,46.202c-9.853,0-12.319,2.466-12.319,2.466c-2.46-7.392-2.46-19.711-2.46-19.711c2.46-2.463,9.853-4.926,14.779-4.035C27.109,36.347,29.569,46.202,29.569,46.202z"/></g></svg>'
export const checkw = '<svg class="nav-img" style="height:100%;" alt="Approve icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.8044 24.939"><defs><style>.cls-1{fill:#fff;}</style></defs><g><path class="cls-1" d="M16.2616,6.3081H4.2761a.88.88,0,0,1,0-1.76H16.2616a.88.88,0,1,1,0,1.76Z"/><path class="cls-1" d="M26.0214.9219a1.5555,1.5555,0,0,0-2.127.5755L15.3224,16.433l-4.3656-5.7685a1.5581,1.5581,0,1,0-2.4851,1.88l5.7869,7.6475a1.5592,1.5592,0,0,0,1.2419.6178c.033,0,.0662-.0011.0994-.0032a1.5571,1.5571,0,0,0,1.2527-.7793L26.5974,3.0489A1.558,1.558,0,0,0,26.0214.9219Z"/><path class="cls-1" d="M18.7776,22.5293a.65.65,0,0,1-.6493.6493H2.4094a.65.65,0,0,1-.649-.6493V2.41a.65.65,0,0,1,.649-.6493H18.1283a.65.65,0,0,1,.6493.6493V8.0551l1.76-3.0672V2.41A2.4124,2.4124,0,0,0,18.1283,0H2.4094A2.4121,2.4121,0,0,0,0,2.41v20.12a2.4121,2.4121,0,0,0,2.4094,2.41H18.1283a2.4124,2.4124,0,0,0,2.41-2.41V15.9641l-1.76,3.0672Z"/><path class="cls-1" d="M11.8926,9.9562l.7918,1.0463h3.5772a.88.88,0,0,0,0-1.76H11.0742A2.7181,2.7181,0,0,1,11.8926,9.9562Z"/><path class="cls-1" d="M8.0657,9.4262a2.7633,2.7633,0,0,1,.2886-.1841H4.2761a.88.88,0,0,0,0,1.76H7.0635A2.7072,2.7072,0,0,1,8.0657,9.4262Z"/><polygon class="cls-1" points="15.402 13.936 14.905 13.936 15.188 14.31 15.402 13.936"/><path class="cls-1" d="M4.2761,13.9365a.88.88,0,0,0,0,1.76H9.3852l-1.3321-1.76Z"/><path class="cls-1" d="M4.2761,18.6309a.88.88,0,0,0,0,1.76h8.6614l-1.3321-1.76Z"/><polygon class="cls-1" points="18.778 16.674 18.778 19.031 20.538 15.964 20.538 13.606 18.778 16.674"/></g></svg>'
export const pencilw = '<svg class="nav-img" style="height:100%;" alt="Pencil icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.3994 18.5775"><defs><style>.cls-1{fill:#fff;}</style></defs><g><path class="cls-1" d="M12.287,3.0175,4.6512,16.0236a1.6055,1.6055,0,0,1-.5479.5574L.98,18.4876a.6078.6078,0,0,1-.9244-.51L0,14.2055a1.6048,1.6048,0,0,1,.2208-.8366L7.8333.4028A.8155.8155,0,0,1,8.95.1124l3.0471,1.7889A.8156.8156,0,0,1,12.287,3.0175Z"/></g></svg>'
export const addmodw = '<svg class="nav-img" style="height:100%;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.8607 24.0619"><defs><style>.cls-1{fill:#fff;}</style></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_2-2" data-name="Layer 2"><path class="cls-1" d="M22.3446,9.0444l-.2229.0069a9.262,9.262,0,0,1-3.0771-.46,10.5972,10.5972,0,0,1-2.6365-1.7l-.1419-.1276a.5006.5006,0,0,0-.6959.0252l-.1325.1379a8.0913,8.0913,0,0,1-2.1255,1.5266A10.1768,10.1768,0,0,1,9.9421,9H9.7429a.5.5,0,0,0-.5007.5045l.0016.1992c.0021.2729.0649,6.7089,1.1875,9.0419,1.1339,2.3568,4.9483,5.0374,5.11,5.15l.1086.0758a.5006.5006,0,0,0,.557.0108l.1114-.0715c.4536-.2914,4.4543-2.9157,5.22-5.3418A63.97,63.97,0,0,0,22.8394,9.81l.0193-.2221A.5006.5006,0,0,0,22.3446,9.0444Zm-7.95,5.6412c-1.7172,0-2.1464.4293-2.1464.4293a13.8081,13.8081,0,0,1-.4293-3.4343,3.62,3.62,0,0,1,2.5757-.7032A9.0567,9.0567,0,0,0,14.395,14.6856Z"/><path class="cls-1" d="M11.2991,5.1786H7.0636V.9425a.9425.9425,0,0,0-1.885,0V5.1786H.9425a.9425.9425,0,0,0,0,1.885H5.1786V11.3a.9425.9425,0,0,0,1.885,0V7.0636h4.2355a.9425.9425,0,1,0,0-1.885Z"/></g></g></svg>'




