import App from '../../../App'
import {render, html} from 'lit/html.js'
import {gotoRoute, anchorRoute } from '../../../Router'
import Validate from '../../../functions/Validate'
import ForgotApi from '../../../api/staff/SForgotApi'
import StaffApi from '../../../api/staff/StaffApi'


class TokenValidationView {
  init(){    
    document.title = 'Validating Email'    
    this.validatingType = null
    this.render()  
    this.initValidateToken()
  }

  initValidateToken() {
    console.log('validating token')
    const statusTab = document.getElementById('status-tab')
    const urlParams = new URLSearchParams(window.location.search)
    const token =  urlParams.get('token')
    const type =  urlParams.get('type')
    //security check
    const safeToken = Validate.urlCheck(token)
    const safeType = Validate.urlCheck(type)
    if(safeType == false || safeToken == false){
      statusTab.innerHTML = '<c-error errorSource="backhome" errorInfo="urlcorrupt"></c-error>'
      return
    }
     if(type == "forgot"){ 
      statusTab.innerHTML = '<c-waiting waitSource="validating"></c-waiting>'
      this.validateForgotTkn(token)
    } else if(type == "update"){ 
      statusTab.innerHTML = '<c-waiting waitSource="validating"></c-waiting>'
      this.validateUpdateTkn(token)
    } else {
      console.log('Error in url') //because no "type"
      statusTab.innerHTML = '<c-error errorSource="backhome" errorInfo="urlcorrupt"></c-error>'
    }
  }

//http://localhost:1234/token-validation?type=register&token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoidW1tcmFoZWlsQGdtYWlsLmNvbSIsImlhdCI6MTY3MTk4NzY0MSwiZXhwIjoxNjcxOTg5NDQxfQ.F6mNAJecPf0OO1OlsBgbyrcTn954vaHluJoafmm56ww
//http://localhost:1234/token-validation?type=forgot&token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7Il9pZCI6IjYzYTg4NGI5MzQwM2Q5MDdiZTc0NTU5OCJ9LCJpYXQiOjE2NzE5ODg5OTMsImV4cCI6MTY3MTk5MDc5M30.DVUmxs7amR6u2n87WY0EgkeajklAEx4Rev75KnWMFnE



  async validateForgotTkn(token){
    const statusTab = document.getElementById('status-tab')
    try {
      const verifyStatus = await ForgotApi.verifyForgotToken(token)    
      if(verifyStatus == true){
        gotoRoute('/reset')
      } else {
        statusTab.innerHTML = '<c-error errorSource="validating" errorInfo="forgot"></c-error>'
      }
    }catch(err){      
      statusTab.innerHTML = '<c-error errorSource="validating" errorInfo="forgot"></c-error>'
    }
  }
  
  //updating email
  async validateUpdateTkn(token){
    const statusTab = document.getElementById('status-tab')
    try {
      const verifyStatus = await StaffApi.validateUpdateEmail(token)    
      if(verifyStatus == true){
        statusTab.innerHTML = '<c-success successSource="update" successInfo="email"></c-success>'
      } else {
        statusTab.innerHTML = '<c-error errorSource="validating" errorInfo="update"></c-error>'
      }
    }catch(err){      
      statusTab.innerHTML = '<c-error errorSource="validating" errorInfo="update"></c-error>'
    }
  }

  
  render(){
    const template = html`
      <div class="sign-content v-center">
        <div class="form-box">           
          <br>
          <div id="status-tab">
            <div class="spinner sp-sml"></div>
          </div>
        </div>
      </div> 
    `

    render(template, App.rootEl)
  }
}

export default new TokenValidationView()