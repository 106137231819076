import App from '../../../App'
import {render, html} from 'lit/html.js'
import SAuth from '../../../SAuth'
import Validate from '../../../functions/Validate'
import StaffFeedbackApi from '../../../api/StaffFeedbackAPI'
import Modal from '../../../functions/Modal'
import Font from '../../../functions/Font'


class FeedbackView {
  init(){    
    document.title = 'Feedback'    
    this.render()  
    Font.initFont()   
  }

  enterBtn(e){
    const mainTab = document.getElementById('main-tab')
    if (e.key === 'Enter' && !mainTab.classList.contains('hidden')) {
      this.sendFeedbackHandler()
    }
  }
  

  async sendFeedbackHandler(){
    //Refractoring code:
    //const mainTab = document.getElementById('main-tab')
    //const statusTab = document.getElementById('status-tab')
    const subjectInput = document.getElementById("subject")
    const feedbackInput = document.getElementById("feedback")
    
    let validated = true
    let subject = false
    if(feedbackInput.value == ""){
      Validate.inputWarn(feedbackInput.id, "*Feedback required")
      validated = false
    } else {
      const fbStatus = Validate.safeCheck(feedbackInput.value)
      if (fbStatus == false){
        Validate.inputWarn(feedbackInput.id, "*No special characters")
        validated = false
      } else if (feedbackInput.value.length < 8 || feedbackInput.value.length > 1000 ){
        Validate.inputWarn(feedbackInput.id, "*Enter 8 to 1000 characters")
        validated = false
      } 
    }
    if(subjectInput.value !== ""){
      const sbStatus = Validate.safeCheck(subjectInput.value)
      subject = true
      if (sbStatus == false){
        Validate.inputWarn(subjectInput.id, "*No special characters")
        validated = false
      } else if (subjectInput.value.length < 4 || subjectInput.value.length > 50 ){
        Validate.inputWarn(subjectInput.id, "*Enter 4 to 50 characters")
        validated = false
      } 
    }
    if(validated == true) { 
      //mainTab.classList.add('hidden')
      //statusTab.innerHTML = '<c-waiting waitSource="feedback"></c-waiting>'
      Modal.confirmModal('submit','feedback', 'wait')

      let feedbackMessage = {}
      feedbackMessage.feedback = feedbackInput.value
      feedbackMessage.user = SAuth.currentUser._id
      if(subject == true){
        feedbackMessage.subject = subjectInput.value
      }
      try {
        const feedbackObject = JSON.stringify(feedbackMessage);
        const registered = await StaffFeedbackApi.newFeedback(feedbackObject)
        if(registered == false){
          //statusTab.innerHTML = '<c-error errorSource="goback"></c-error>'
          Modal.confirmModal('submit','feedback', 'error')
        } else { 
          //statusTab.innerHTML = '<c-success successSource="feedback"></c-success>'
          Modal.confirmModal('submit','feedback', 'success')
        }
      }catch(err){
        //statusTab.innerHTML = '<c-error errorSource="goback"></c-error>'
        Modal.confirmModal('submit','feedback', 'error')
      }
    }
  }

  
  render(){
    const template = html`
      
      <div class="main-content" id="main-content">
        <main-app-header pagename="${document.title}"></main-app-header>
        <div class="content-panel">      
          <div id="main-tab"> 
            <div class="form-left">
              <div class="input-group">
                <label class="para label">Subject:</label>
                <p id="subject-wrn" class="input-require">*No special characters & max 50 </p>
                <input id="subject" class="para" placeholder="(Optional)" @keyup=${Validate.clearWarning.bind(Validate)} maxlength="50">
              </div>

              <div class="input-group">
                <label class="para label">Message:</label>
                <p id="feedback-wrn" class="input-require">*No special characters & max 1000 </p>
                <textarea class="para" rows="8" cols="50" id="feedback" @keyup=${Validate.clearWarning.bind(Validate)} placeholder="Feedback..." maxlength="1000"></textarea>
              </div>

              <div class="redirect-button forum-action-btn action" @click=${this.sendFeedbackHandler}><strong>Send</strong></div>
            </div>
          </div>
          <div id="status-tab"></div>
        </div> 
      </div> 
    `

    render(template, App.rootEl)
  }
}

export default new FeedbackView()