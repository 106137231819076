import App from '../App'
import Toast from '../Toast'


class PostAPI {

    //get all -- unapproved posts
    async getUnapprovedPosts(group){
        try{
            const response = await fetch(`${App.apiBase}/postcheck/${group}`, {
                headers: { "Authorization": `Bearer ${localStorage.accessToken}`}
              })
            if(!response.ok){      
              const err = await response.json()
              if(err) console.log(err)
              Toast.show(`${response.status}: ${err.message}`, 'error')   
              if(typeof fail == 'function') fail()
              return false
            }
            const data = await response.json()
            return data
        } catch(err) {
            console.log(err)
            Toast.show(err, 'error')
            return false
        }
    }

    //get post by id - I don't think this is needed
    /*
    async getPost(group, id){
        try{
            const response = await fetch(`${App.apiBase}/${group}post/${id}`, {
            headers: { "Authorization": `Bearer ${localStorage.accessToken}`}
            })
            if(!response.ok){ 
                const err = await response.json()
                if(err) console.log(err)    
                throw new Error('Problem getting post')
            }
            const data = await response.json()
            return data
        } catch(err) {
            console.log(err)
            Toast.show(err, 'error')
            return false
        }
    }
    */

    //get posts by thread Id
    async getPostbyThreadId (group, threadId, fail = false){
        try{
            const response = await fetch(`${App.apiBase}/${group}post/posts/${threadId}`, {
                headers: { "Authorization": `Bearer ${localStorage.accessToken}`}
              })
            if(!response.ok){      
              const err = await response.json()
              if(err) console.log(err)
              Toast.show(`${response.status}: ${err.message}`, 'error')   
              if(typeof fail == 'function') fail()
              return false
            }
            const data = await response.json()
            return data
        } catch(err) {
            console.log(err)
            Toast.show(err, 'error')
            return false
        }
    }
    

    async updatePost(group, postId, postData, fail = false){
        if(!postData || !postId) return false
        console.log(postData)
        try{
          let responseHeader
          responseHeader = {
            method: "PUT",    
            headers: {
                "Authorization": `Bearer ${localStorage.accessToken}`,
                "Content-Type" : "application/json", 
                "Origin": App.origin
            },
            body: postData
          }
          const response = await fetch(`${App.apiBase}/${group}post/${postId}`, responseHeader)
          if(!response.ok){
            const err = await response.json()
            if(err) console.log('not ok' + err)
            console.log(err)
            Toast.show(`${response.status}: ${err.message}`, 'error')   
            if(typeof fail == 'function') fail()
            return false
          }
          const data = await response.json()
          return data
        } catch(err) {
          console.log(err)
          Toast.show(err, 'error')
          return false
        }
    }

    // delete post 
    async deletePost(group, postId, fail = false){
        if(!postId) return false
        try{
          let responseHeader
          responseHeader = {
            method: "DELETE",    
            headers: {
                "Authorization": `Bearer ${localStorage.accessToken}`,
                "Origin": App.origin
            },
          }
          const response = await fetch(`${App.apiBase}/${group}post/${postId}`, responseHeader)
          if(!response.ok){
            const err = await response.json()
            if(err) console.log('not ok' + err)
            console.log(err)
            Toast.show(`${response.status}: ${err.message}`, 'error')   
            if(typeof fail == 'function') fail()
            return false
          }
          const message = await response.json()
          //console.log(message)
          Toast.show(`Post deleted`) 
          return true
        } catch(err) {
          console.log(err)
          Toast.show(err, 'error')
          return false
        }
    }
    

}

export default new PostAPI()