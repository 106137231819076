import {LitElement, html} from 'lit'
import {gotoRoute, navClick} from '../../Router'
import Modal from '../../functions/Modal'

customElements.define('c-success', class Features extends LitElement {
  constructor(){
    super()    
  } 
  static get properties(){
    return {
      successSource: { type: String },
      successInfo: { type: String }
    }
  }

  firstUpdated(){
    super.firstUpdated()
  }

  backtoMain(){
    const mainTab = document.getElementById('main-tab')
    const statusTab = document.getElementById('status-tab')
    mainTab.classList.remove('hidden')
    statusTab.innerHTML = ''
  }

  backto(e){
    Modal.closeModal()
    const pathname = e.target.closest('nav').getAttribute("nav-link")
		gotoRoute(pathname)
  }

  close(){
    const outerBox = this.shadowRoot.getElementById('outer-box')
    outerBox.classList.add('hidden')
  }

  render(){    
    return html`
      <style>      
      
        * { box-sizing: border-box; margin: 0px; padding: 0px; }
        #outer-box { text-align: center; }
        .mi-text { margin: 30px auto;  width: 100%; font-size: 1.5em; }
        .primary { background-color: var(--brand-color); transition: .2s;  }
        .primary:hover { background-color: var(--two-color);  }
        .action { background-color: var(--action-color) ; transition: .2s; }
        .action:hover { background-color: rgb(243, 183, 18); }
        .success{font-size: 2em;}
        #tick{ width: 100px; }

        .redirect-button {
          color: white;
          border: none;
          border-radius: 5px;
          padding: 7px;
          margin: 20px auto;
          text-decoration: none;
          width: 200px; 
          cursor: pointer;
          font-size: 1.1em;
          text-align: center;
        }

        .hidden { display: none; }

      </style>
      
      
      <div id="outer-box">
        <p class="mi-text success">Success </p>
        
        <svg id="tick" alt="Success Tick" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.3404 56.3404">
          <defs><style>.cls-1{fill:#02cc16;}.cls-2{fill:#fff;}</style></defs>
          <g><g>
            <circle class="cls-1" cx="28.1702" cy="28.1702" r="28.1702"/>
            <path class="cls-2" d="M23.8491,46.9008a3.5027,3.5027,0,0,1-2.5572-1.11L11.1684,34.9545a3.5,3.5,0,0,1,5.1152-4.7793l7.09,7.5889L39.6923,13.0122a3.5,3.5,0,0,1,5.8438,3.8535L26.7714,45.3276a3.4975,3.4975,0,0,1-2.6274,1.5605C24.0459,46.8969,23.9472,46.9008,23.8491,46.9008Z"/>
          </g></g>
        </svg>
        
        <br><br>

        ${this.successSource == "modt-deleted" ? html`
          <p class="mi-text"> Deleted.</p>
          <br><br>
          ${this.successInfo == "thread" ? html`
            <nav class="redirect-button action" nav-link="/mod/checkthread" @click="${navClick}">Back to Threads</nav>
          ` : html``}
          ${this.successInfo == "post" ? html`
            <nav class="redirect-button action" nav-link="/mod/checkposts" @click="${navClick}">Back to Posts</nav>
          ` : html``}

          <nav class="redirect-button primary" nav-link="/" @click="${navClick}">Main Menu</nav>
        ` : html``}

        ${this.successSource == "linksent" ? html`
          <p class="mi-text"> Email sent!</p>
          <p>Please check your inbox.</p>
          <br><br>
          <i>If you can't find it ...</i>
          <nav class="redirect-button primary" @click="${this.backtoMain}">Try again</nav>
          ${this.successInfo == "update" ? html`
            <nav class="redirect-button action" nav-link="/user/profile" @click="${navClick}">My Profile</nav>
          ` : html``}
        ` : html``}


        ${this.successSource == "deleted" ? html`
          <p class="mi-text"> Item deleted.</p>
          <br><br>
          <button class="redirect-button action" @click=${this.backtoMain.bind(this)}>Back to page</button>
        ` : html``}


        ${this.successSource == "update" ? html`
          ${this.successInfo == "email" ? html`
            <p class="mi-text"> Your email has been updated.</p>
          ` : html``}
          ${this.successInfo == "profile" ? html`
            <p class="mi-text"> Your profile has been updated.</p>
          ` : html``}
          <br><br>
          <nav class="redirect-button action" nav-link="/user/profile" @click="${navClick}">Profile</nav>
        ` : html``}


        ${this.successSource == "new-mod" ? html`
          <p class="mi-text"> New moderator added.</p>
          <br><br>
          <nav class="redirect-button action" nav-link="/admin/mods" @click="${navClick}">View Moderators</nav>
        ` : html``}

        
          ${this.successSource == "feedback" ? html`
            <p class="mi-text"> Thank you, we look forward to reading your feedback.</p>
            <br><br>
            <nav class="redirect-button primary" nav-link="/" @click=${this.backto.bind(this)}>Home</nav>
          ` : html`
            <nav class="redirect-button primary" nav-link="/" @click="${navClick}">Home</nav>
          `}
 
        
      </div>

    `

  } 
})
