// TO DO
// 1) Remove all EventListeners in case of navigating! via Events
	// document.removeEventListener('keyup', this.signEnter) ...  
	// post view & sign view... (revise all forms to improve UX)

// import Toast from './Toast'

import fourOFourView from './views/pages/404'
import homeView from './views/pages/1home'
import settingsView from './views/pages/4settings'
import modPolicy from './views/pages/2modpolicy'
 
import signinView from './views/pages/sign/2signin' //& forgot
import resetView from './views/pages/sign/5reset'
import tokenValidationView from './views/pages/sign/9token-validation'

// ADMIN views - RESTRICTED
import adminmainView from './views/pages/admin/0main' 
import adminfeedbackView from './views/pages/admin/1aviewfeedback' 
import adminStaffFeedbackView from './views/pages/admin/1bviewstafffeedback' 
import adminmaodsView from './views/pages/admin/2aviewmods' 
import adminnewmodView from './views/pages/admin/2bnewmod' 
import admineditmodView from './views/pages/admin/2ceditmod' 

import modCheckView from './views/pages/moderator/1checkposts' 
import modpostactionsView from './views/pages/moderator/2postactions' 
import modcheckthreadView from './views/pages/moderator/3checkthreads'
import modThreadActionsView from './views/pages/moderator/4threadactions'
import modViewthreadView from './views/pages/moderator/5viewthread'

import profileView from './views/pages/user/1aprofile'
import editprofileView from './views/pages/user/1feditprofile'
import newfeedbackView from './views/pages/user/3feedback'

import testView from './views/pages/ztest'

import SAuth from './SAuth'

// define routes
const routes = {
	'/ztest' : testView,
	'404' : fourOFourView,
	'/': homeView,	// 1
	'/modpolicy': modPolicy,//2 
	'/settings': settingsView, // 4 

	'/signin': signinView, // 2 & forgot 
	'/reset': resetView, // 5
	'/token-validation': tokenValidationView, // 9
	
	// ADMIN views - RESTRICTED   - access
	'/admin/main': adminmainView, // 0menu
	'/admin/feedback': adminfeedbackView, // 1aviewfeedback 
	'/admin/stafffeedback': adminStaffFeedbackView, // 1bviewstafffeedback 
	'/admin/mods': adminmaodsView, // 2aviewmods
	'/admin/newmod': adminnewmodView, // 2bnewmod
	'/admin/editmod': admineditmodView, // 2ceditmod

	'/mod/checkposts': modCheckView, // 1checkposts
	'/mod/postactions': modpostactionsView, // 2postactions 
	'/mod/checkthread': modcheckthreadView, // 3checkthread 
	'/mod/threadactions': modThreadActionsView, //   4threadactions
	'/mod/viewthread': modViewthreadView, // 5viewthread  4threadactions

	'/user/profile': profileView, // 1aprofile  
	'/user/editprofile': editprofileView, // 1feditprofile 
	'/user/feedback': newfeedbackView, // 3feedback

}

class Router {
	constructor(){
		this.routes = routes
		this.lastRoute = null
		this.authRequired = ["admin"]
	}

	init(){
		// initial call
		const newRoute = window.location.pathname
		if (this.lastRoute == newRoute){
			return
		} else {
			this.route(newRoute)
			this.lastRoute = newRoute
		}
		// on back/forward
		window.addEventListener('popstate', () => {
			this.route(window.location.pathname)
		})
	}
	
	async route(fullPathname){
		let pathname = fullPathname.split('?')[0]
		const accessPath = fullPathname.split('/')[1];
		
		if(accessPath == 'admin' && !SAuth.currentUser.accessLevel >= 6) { 
			pathname = '/'
			window.history.pushState({}, pathname, window.location.origin + pathname);
		}	
		const route = this.routes[pathname]
		if(route){
			// if route exists, run init() of the view
			this.routes[pathname].init()
		}else{			
			this.routes['404'].init()			
		}
		const scroll = window.pageYOffset
		if (scroll > 20) {
		  window.scrollTo(0, 0)
		}
	}

	gotoRoute(pathname){
		window.history.pushState({}, pathname, window.location.origin + pathname);
		this.route(pathname)
	}	

	navClick(e){
		e.preventDefault()
		const pathname = e.target.closest('nav').getAttribute("nav-link")
		gotoRoute(pathname)
	}
}

// create appRouter instance and export
const AppRouter = new Router()
export default AppRouter


// programmatically load any route
export function gotoRoute(pathname){
	AppRouter.gotoRoute(pathname)
}

// programmatically load any nav-link route
export function navClick(e){
	AppRouter.navClick(e)
}

// allows anchor <a> links to load routes
export function anchorRoute(e){
	e.preventDefault()	
	const pathname = e.target.closest('a').pathname
	AppRouter.gotoRoute(pathname)
}

