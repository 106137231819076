import {LitElement, html} from 'lit'
import {anchorRoute, gotoRoute, navClick} from '../../Router'


customElements.define('forum-btn', class ForumComponents extends LitElement {
  constructor(){
    super()    
  }

  static get properties(){
    return {
      btnType: {
        type: String
      }
    }
  }

  
  firstUpdated(){
    super.firstUpdated()
  }

  render(){    
    return html`
    <style>      
    
      * { box-sizing: border-box; margin: 0px; padding: 0px; }
      .forum-button {
        margin: 20px auto;
        color: white;
        border-radius: 500px;
        text-decoration: none;
        padding: 7px 15px;
        display: flex; align-items: center; justify-content: center;
        width: 220px;
        font-size: 1.2em;
        height: 45px;
        user-select: none;

      } 

      .action { background-color: var(--action-color) ; }
      .forum-action-btn { transition: .4s; }
      .forum-action-btn:hover { background-color: rgb(243, 183, 18); }

      .primary { background-color: var(--brand-color); }
      .forum-btn { cursor: pointer; transition: background-color 0.4s ease-out; }
      .forum-btn:hover { background-color: var(--two-color); }

      .back-img { width: 25px; margin: 0 0 0 20px; padding: 3px; }

      
        
      /* ------------------- RESPONSIVE ------------------- */
      /* Mobile styles 
        @media all and (max-width: 768px){   }
      */

      @media all and (max-width: 350px){ 
        .forum-button {
          margin: 10px auto;
          font-size: 1.1em;
          height: 35px;
        }

      }
      
       /* Desktop styles 
        @media all and (min-width: 769px){  }
      */



      </style>

        <a href="/" class="forum-button forum-btn primary" @click=${anchorRoute}>Back to menu
          <img class="back-img" alt="back" src="https://hashtag-metoo.com/images/icons/sw00-back.svg">
        </a>
              

    `
  }
  
})
