import App from '../../App'
import {render, html} from 'lit/html.js'
import SAuth from '../../SAuth'
import Moderate from '../../functions/Moderate'
import Font from '../../functions/Font'
import '../../components/ps-home-btn'
import {badgew, checkw, pencilw} from '../../data/Images'

class HomeView {
  init(){    
    document.title = 'Admin Home'    
    this.render() 
    Font.initFont()   
    Moderate.modThreads = null 
    Moderate.modPosts = null
  }

  render(){
    const template = html`
      <div class="main-content">
        <main-app-header pagename="${document.title}"></main-app-header>
        <feedback-widget class="feedback-widget"></feedback-widget>
        <div class="content-panel">  
        <p class="app-info para">*UPDATE BEFORE APPROVING.</p>
        
          ${SAuth.currentUser.accessLevel >= 6 ? html`
          <ps-home-btn group="admin" imageName="${badgew}"pageLink="/admin/main">Admin Panel</ps-home-btn>
          ` : html``}
          
          <ps-home-btn group="peach" imageName="${checkw}"pageLink="/mod/checkposts">Peach Posts</ps-home-btn>
          <ps-home-btn group="peach" imageName="${pencilw}"pageLink="/mod/checkthread">Peach Threads</ps-home-btn>
          <ps-home-btn group="azure" imageName="${checkw}"pageLink="/mod/checkposts">Azure Posts</ps-home-btn>
          <ps-home-btn group="azure" imageName="${pencilw}"pageLink="/mod/checkthread">Azure Threads</ps-home-btn>
          <ps-home-btn group="forrest" imageName="${checkw}"pageLink="/mod/checkposts">Forrest Posts</ps-home-btn>
          <ps-home-btn group="forrest" imageName="${pencilw}"pageLink="/mod/checkthread">Forrest Threads</ps-home-btn>
         
        </div>
      </div>
    `

    render(template, App.rootEl)
  }
}

export default new HomeView()