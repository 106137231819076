import Router from './Router'
import SAuth from './SAuth'
import Toast from './Toast'


class App {
  constructor(){
    this.name = "Hashtag Metoo Admin"
    this.version = "2.0.0"
    this.apiBase = 'https://hashback.hashtag-metoo.com'
    this.rootEl = document.getElementById("root")
    this.origin = "https://amp.hashtag-metoo.com"
    this.appcode = "mt"
  }
  //App.name

  //https://hashback.hashtag-metoo.com

  //http://localhost:1234
  //http://localhost:3000 

  //https://hashtag-metoo.com
  //https://amp.hashtag-metoo.com

  // https://hashbackenv.hashtag-me2.com = WORKING
  //this.apiBase = 'https://hashend.hashtag-metoo.com'
  //this.apiBase = 'https://hashback.hashtag-metoo.com'

  // update apiBase to link when live: 
  // http://localhost:3000
  // update origin to correct link when live: 
  // ** No backslashes on end
  // https://backend.hashtag-me2.com
  // ** should I try without the "s" in http
  // http://backend.hashtag-me2.com

  init() { 
    Toast.init()  
    SAuth.userCheck(() => { Router.init()}) 
  }

  resizeListener(){
    document.body.style.height = window.innerHeight + 'px';  
  }

}


export default new App()