import {LitElement, html} from 'lit'
import {anchorRoute} from '../../Router'
import SSign from '../../SSign'
import Modal from '../../functions/Modal'

customElements.define('c-error', class Features extends LitElement {
  constructor(){
    super()    
  } 
  static get properties(){
    return {
      errorSource: { type: String },
      errorInfo: { type: String }
    }
  }

  firstUpdated(){
    super.firstUpdated()
  }

  backToMain(){
    const mainTab = document.getElementById('main-tab')
    const statusTab = document.getElementById('status-tab')
    mainTab.classList.remove('hidden')
    statusTab.innerHTML = ''
    if(this.errorInfo == "enterEvent"){
      document.addEventListener('keyup', SSign.signEnter) 
    }
  }

  close(){
    const outerBox = this.shadowRoot.getElementById('outer-box')
    outerBox.classList.add('hidden')
  }

  //<img id="warn" alt="Error Exclamation" src="https://hashtag-metoo.com/images/icons/warning.svg">

  render(){    
    return html`
      <style>      
        * { box-sizing: border-box; margin: 0px; padding: 0px; }
        #outer-box { text-align: center; }
        .mi-text { margin: 30px auto;  width: 100%; font-size: 1.5em; }
        #warn{ width: 100px; }
        .action { background-color: var(--action-color) ; transition: .2s; }
        .action:hover { background-color: rgb(243, 183, 18); }

        .redirect-button {
          color: white;
          border: none;
          border-radius: 5px;
          padding: 7px 14px;
          margin: 20px auto;
          text-decoration: none;
          width: 200px; 
          cursor: pointer;
          font-size: 1.1em;
          text-align: center;
        }
        .hidden { display: none; }

      </style>

      <div id="outer-box">
        <p class="mi-text"> Something went wrong </p>
        
        <svg version="1.1" id="warn" alt="Error Exclamation" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
          width="125.557px" height="110px" viewBox="0 0 125.557 110" enable-background="new 0 0 125.557 110" xml:space="preserve">
          <g>
            <path fill="#D51F26" d="M10.447,110.037h104.525c8.072,0,13.084-8.814,8.985-15.799L71.696,5.158
            c-4.034-6.877-13.937-6.877-17.973,0L1.46,94.238C-2.638,101.223,2.375,110.037,10.447,110.037z"/>
            <g>
              <ellipse fill="#FFFFFF" cx="62.71" cy="92.704" rx="8.629" ry="8.668"/>
              <path fill="#FFFFFF" d="M57.472,21.455h10.475c1.139,0,2.056,0.938,2.035,2.082l-0.971,53.648
                c-0.02,1.116-0.925,2.008-2.037,2.008h-8.529c-1.11,0-2.016-0.892-2.036-2.008l-0.971-53.648
                C55.417,22.393,56.333,21.455,57.472,21.455z"/>
            </g>
          </g>
        </svg>
        

        <br><br>


        ${this.errorSource == "mod-menu" ? html`
        <br><a href="/" class="redirect-button action" @click=${anchorRoute}>Back to main</a>
        ` : html``}

        ${this.errorSource == "admin-menu" ? html`
        <br><a href="/admin/main" class="redirect-button action" @click=${anchorRoute}>Back to main</a>
        ` : html``}

        ${this.errorSource == "tryagain" ? html`
          <p class="mi-text"> There was a problem getting the information.</p>
          <p class="mi-text"> Please check your connection and try again.</p>
        ` : html``}

        ${this.errorSource == "goback" ? html`
          <button class="redirect-button action" @click=${this.backToMain.bind(this)}>Go back</button>
        ` : html``}

        ${this.errorSource == "close-modal" ? html`
          <button class="redirect-button action" @click=${Modal.closeModal.bind(Modal)}>Close</button>
        ` : html``}

        ${this.errorSource == "posts" ? html`
        <br><a href="/forum/threads" class="redirect-button action" @click=${anchorRoute}>Back to threads</a>
        ` : html``}

        ${this.errorSource == "mod-posts" ? html`
        <br><a href="/mod/checkposts" class="redirect-button action" @click=${anchorRoute}>Back to threads</a>
        ` : html``}

        ${this.errorSource == "validating" ? html`
          ${this.errorInfo == "forgot" ? html`
          <br><a href="/signin" class="redirect-button action" @click=${anchorRoute}>Start again</a>
          ` : html``}
          ${this.errorInfo == "update" ? html`
          <br><a href="/user/editprofile" class="redirect-button action" @click=${anchorRoute}>Start again</a>
          ` : html``}
        ` : html``}

        ${this.errorSource == "backhome" ? html`
          ${this.errorInfo == "urlcorrupt" ? html`
            <p class="mi-text"> There was an error with the url, please try again.</p>
          ` : html``}
          ${this.errorInfo == "info-error" ? html`
            <p class="mi-text"> There was a problem getting the information.</p>
            <p class="mi-text"> Please check your connection and try again.</p>
          ` : html``}

          <br><a href="/" class="redirect-button action" @click=${anchorRoute}>Back Home</a>
        ` : html``}

      </div>
    `
  }
  
})
