import App from '../App'
import Toast from '../Toast'

//VErsion 1 //get all -- approved threads
//Version 2 //get threads by subtopic id 

class ThreadAPI {

  /*
    async newThread (group, threadData, fail = false){
        try{
            const response = await fetch(`${App.apiBase}/${group}thread/`, {
                method: 'POST',      
                headers: {
                        "Authorization": `Bearer ${localStorage.accessToken}`,
                        "Content-Type" : "application/json", 
                        "Origin": App.origin
                    },
                body: threadData
            })
            if(!response.ok){      
              const err = await response.json()
              if(err) console.log(err)  
              Toast.show(`${response.status}: ${err.message}`, 'error')   
              if(typeof fail == 'function') fail()
              return false
            }
            const data = await response.json()
            //console.log(data)
            return true
        } catch(err) {
            console.log(err)
            Toast.show(err, 'error')
            return false
        }
    }
  */

    //get all -- unapproved threads
    async getUnapprovedThreads(group){
        try{
            const response = await fetch(`${App.apiBase}/threadcheck/${group}`, {
                headers: { "Authorization": `Bearer ${localStorage.accessToken}`}
              })
            if(!response.ok){      
              const err = await response.json()
              if(err) console.log(err)
              Toast.show(`${response.status}: ${err.message}`, 'error')   
              if(typeof fail == 'function') fail()
              return false
            }
            const data = await response.json()
            return data
        } catch(err) {
            console.log(err)
            Toast.show(err, 'error')
            return false
        }
    }

    //get thread by id
    async getThread(group, id){
        try{
            const response = await fetch(`${App.apiBase}/${group}thread/${id}`, {
            headers: { "Authorization": `Bearer ${localStorage.accessToken}`}
            })
            if(!response.ok){ 
                const err = await response.json()
                if(err) console.log(err)    
                throw new Error('Problem getting thread')
            }
            const data = await response.json()
            return data
        } catch(err) {
            console.log(err)
            Toast.show(err, 'error')
            return false
        }
    }

    // put - update thread
    async updateThread(group, threadId, threadData, fail = false){
        if(!threadData || !threadId || !group) return false
        //console.log(threadData)
        try{
          let responseHeader
          responseHeader = {
            method: "PUT",    
            headers: {
                "Authorization": `Bearer ${localStorage.accessToken}`,
                "Content-Type" : "application/json", 
                "Origin": App.origin
            },
            body: threadData
          }
          const response = await fetch(`${App.apiBase}/${group}thread/${threadId}`, responseHeader)
          if(!response.ok){
            const err = await response.json()
            if(err) console.log('not ok' + err)
            console.log(err)
            Toast.show(`${response.status}: ${err.message}`, 'error')   
            if(typeof fail == 'function') fail()
            return false
          }
          const data = await response.json()
          //console.log(data.thread)
          return data
        } catch(err) {
          console.log(err)
          Toast.show(err, 'error')
          return false
        }
    }

    
    async deleteThread(group, threadId, fail = false){
        if(!threadId) return false
        try{
          let responseHeader
          responseHeader = {
            method: "DELETE",    
            headers: {
                "Authorization": `Bearer ${localStorage.accessToken}`,
                "Origin": App.origin
            },
          }
          const response = await fetch(`${App.apiBase}/${group}thread/${threadId}`, responseHeader)
          if(!response.ok){
            const err = await response.json()
            if(err) console.log('not ok' + err)
            console.log(err)
            Toast.show(`${response.status}: ${err.message}`, 'error')   
            if(typeof fail == 'function') fail()
            return false
          }
          const message = await response.json()
          //console.log(message)
          Toast.show(`Thread deleted`) 
          return true
        } catch(err) {
          console.log(err)
          Toast.show(err, 'error')
          return false
        }
    }
    

}

export default new ThreadAPI()