import {LitElement, html, css} from 'lit';
import {unsafeSVG} from 'lit/directives/unsafe-svg.js';
import { gotoRoute } from '../Router';
import Moderate from '../functions/Moderate';


customElements.define('ps-home-btn', class MyElement extends LitElement {

    static get properties(){
        return {
            group: { type: String },
            pageLink: { type: String },
            imageName: { type: String }
        }
    }

    homeNav(){
        const navPath = this.pageLink.split('/');
        if(navPath[1] == "admin") { gotoRoute(this.pageLink); return }
        if(Moderate.modGroup !== this.group){
            Moderate.modGroup = this.group
            if(navPath[2] == 'checkposts'){ Moderate.modPosts = null }
            if(navPath[2] == 'checkthread'){ Moderate.modThreads = null }
        }
		gotoRoute(this.pageLink + '?section=' + this.group )
    }

    static styles = css`
        * { box-sizing: border-box; }

        .admin { background-color: var(--action-color); } 
        .azure { background-color: var(--azure-group); } 
        .peach { background-color: var(--peach-group); } 
        .forrest { background-color: var(--forrest-group); } 
        .primary { background-color: var(--brand-color); } 

        nav {
            margin: 18px auto;
            width: 350px;
            padding: 12px;
            border-radius: 15px;
            color: white;
            font-size: 1.5em;
            height: 70px;
            position: relative;
            cursor: pointer;
            transition:all .5s;
            user-select: none;
        }
        nav:hover { box-shadow: 0px 0px 6px rgba(0,0,0,0.7); }
          
        div {
            height: 46px;
            width: 46px;
            margin: 0 40px;
            display: flex; align-items: center; justify-content: center;
        }
        svg { max-width: 100%; max-height: 100%; }
          
        slot {
            height: 70px;
            width: 300px;
            text-align: center;
            position: absolute;
            display: flex; align-items: center; justify-content: center;
            top: 0;
            right: 0;
        }
        
        @media all and (max-width: 550px){
            nav {
                margin: 15px auto;
                width: 300px;
                padding: 10px;
                font-size: 1.3em;
                height: 60px;
            }
            slot { height: 60px; width: 270px; }
            div {
                height: 40px;
                width: 40x;
                margin: 0 25px;
            }
        }

        @media all and (max-width: 350px){
            nav {
                margin: 15px auto;
                width: 240px;
                padding: 8px;
                font-size: 1.2em;
                height: 50px;
            }
            slot { height: 50px; width: 210px; }
            div {
                height: 36px;
                width: 36px;
                margin: 0 25px;
            }  
        }

    `

    render() {
        return html`
            <nav class="${this.group}" @click="${this.homeNav}">
                <div>${unsafeSVG(this.imageName)}</div>
                <slot></slot>
            </nav>
        `
    }

})