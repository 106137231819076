import {LitElement, html} from 'lit'
import {gotoRoute} from '../../Router'
import SAuth from '../../SAuth' // for user auth
import PostAPI from '../../api/PostAPI'
import Toast from '../../Toast'
import Moderate from '../../functions/Moderate'
import SubscribeAPI from '../../api/SubscribeAPI'
import Format from '../../functions/Format'
import Font from '../../functions/Font'



customElements.define('mod-post', class MapThread extends LitElement {
  constructor(){
    super()    
  }

  static get properties(){
    return {
      post: { type: Object },
    }
  }
  

  firstUpdated(){
    super.firstUpdated()
    this.fontUpdate()
    this.postUpdate()
  }

  postUpdate(){
    const tTextDiv = this.shadowRoot.getElementById('post-text-formatted')
    const formattedText = Format.formatParagraphText(this.post.postText)
    tTextDiv.innerHTML = formattedText
  }
  

  postActionHandler(){
    Moderate.modpost = this.post
    gotoRoute('/mod/postactions')
  }


  fontUpdate(){
    const fontPrefs = this.shadowRoot.querySelectorAll('.c-para')
    fontPrefs.forEach(fontPref => {
      fontPref.style.fontSize = Font.updatedfont
    })
  }
  
//WORKING - SENDING EMAIL TO SUBS
  async approveHandler(){
    const hidePost = this.shadowRoot.getElementById('mod-post')
    hidePost.classList.add('hidden')
    const newPost = {}
    newPost.approved = true
    newPost.moderator = SAuth.currentUser._id
    const postObject = JSON.stringify(newPost)
    const subscribeObject = this.getSubData()

    try{
      const udStatus = await PostAPI.updatePost(Moderate.modGroup, this.post._id, postObject)
      if(udStatus == false){ 
        Toast.show('Error Approving', 'error')
        return 
      } else {
        this.emailSubs(subscribeObject)
        Toast.show('Post Approved')
        //not working
        for (let i = 0; i < Moderate.modPosts.length; i++) {
          if (Moderate.modPosts[i]._id == this.post._id) {
            Moderate.modPosts.splice(i, 1);
          }
        } 
      }
    }catch(err){
      console.log(err)
      Toast.show(err, 'error')
    }
  }
  

  async emailSubs(subscribeObject){
    try {
      const subStatus = await SubscribeAPI.emailSubscribers(subscribeObject)
      if(subStatus == false){
        console.log('Error sending email to subs')
      } else {
        console.log('Emails sent to subs :)')
      }
    } catch {
      console.log('Error sending email to subs')
    }
  }

  getSubData(){
    let subscribeData = {}
    subscribeData.message = Format.formatParagraphText(this.post.postText) // email content
    subscribeData.thisUserEmail = this.post.user.email // don't send to this address
    subscribeData.group = Moderate.modGroup // group
    subscribeData.threadId = this.post.threadId
    const subscribeObject = JSON.stringify(subscribeData)
    return subscribeObject
  }
  

  render(){    
    return html`
    <style>      
    
    * { box-sizing: border-box; margin: 0px; padding: 0px; }

    .post-wrap {
      border-bottom: solid 2px var(--two-color);
      margin: 10px; 
      padding: 5px;
    }
    .post-name{
      color: var(--three-color); 
      font-size: 1em; 
      font-weight: bold;
      margin-right: 10px;
      display: inline;
      overflow-wrap: break-word;
    }
    .post-date{
      color: rgb(132, 130, 130); 
      font-size: 0.9em;
      display: inline;
    }
    .post-quote { 
      display: block; 
      width: 100%;
      position: relative;
      border-bottom: solid 0.5px #d7d7d7;
      margin-top: 5px;
      padding-left: 10px;
    }
    .quote-text { 
      width: 90%; 
      color: #818080; 
      margin-bottom: 5px; 
    }

    .qbtn-wrap { 
      position: absolute; 
      right: 3px;
      top: -2px;
      width: 9%; 
    }
    .quote-text:before { content: open-quote; }
    .quote-text:after { content: close-quote; }

    .quote-text:before,
    .quote-text:after {
      display: inline-block;
      vertical-align: bottom;
      position: relative;
    }

    .quote-link-wrap {
      height: 25px;
      width: 25px;
      position: relative;
      transition: .4s;
    }

    .quote-link-wrap:hover { transform: rotate(-90deg) !important; }
    .quote-link-wrap:after {
      content: '';
      width: 50%;
      height: 50%;
      right: 6px;
      top: 3px;
      border-top: 6px solid var(--brand-color);
      border-right: 6px solid var(--brand-color);
      border-radius: 4px;
      transform: rotate(45deg);
      position: absolute;
    }

    .post-text { margin: 5px 0; }
    .c-para { font-size: 1.2em;  }

    .reply-link {
      border: solid 2px var(--action-color);
      border-radius: 7px;
      color: var(--action-color);
      width: 50%;
      margin: auto;
      padding: 5px;
      text-align: center;
      transition: .4s;
      font-weight: bold;
      cursor: pointer;
      user-select: none;
    }
    .reply-link:hover { color: rgb(230, 139, 28); }
    .waiting { color: red; margin-bottom: 8px; }
    .two-buttons { width: 100%; display: flex; margin: 5px 0; }
    .primary { background-color: var(--brand-color); }
    .action { background-color: var(--action-color) ; }
    .mod-button{
      width: 50%; 
      padding: 10px;
      border-radius: 500px;
      text-decoration: none;
      cursor: pointer;
      font-size: 1.1em;
      color: white;
      margin: 0 10px;
      text-align: center;
      user-select: none;
    }

    .tick { width: 15px; margin-left: 10px; }
    .hidden { display: none; }
    
      
    /* ------------------- RESPONSIVE ------------------- */
   
  </style>

    <div class="post-wrap" id="mod-post"> 
      <div class="post-name"> ${this.post.user.userName} </div>
      <div class="post-date"> ${new Date(this.post.createdAt).toLocaleDateString()}</div>
      ${this.post.replyPost ? html`
      <div class="post-quote"> 
        <div class="quote-text c-para">${this.post.replyPost}</div> 
        <div class="qbtn-wrap"> <div class="quote-link-wrap"></div>
        </div>
      </div>
      `: html``}
      
      <p id="post-text-formatted" class="post-text c-para"></p>

      <div class="two-buttons">
        <a class="mod-button primary" @click=${this.approveHandler}>Approve
          <img class="tick" alt="tick" src="https://hashtag-metoo.com/images/icons/tick-wh.svg"></a>
        <a class="mod-button action" @click=${this.postActionHandler}>Actions</a>
      </div>
    </div >  

    `
  }
  
})
