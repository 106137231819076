import App from '../../../App'
import {render, html} from 'lit/html.js'
import Toast from '../../../Toast'
import StaffFeedbackApi from '../../../api/StaffFeedbackAPI'
import Font from '../../../functions/Font'

class ViewfeedbackView {
  init(){    
    document.title = 'Staff Feedback'    
    this.feedback = null
    this.render()  
    Font.initFont() 
    this.getFeedback()
  }

  async getFeedback(){
    try{
      this.feedback = await StaffFeedbackApi.getAllFeedback()
      if( document.title !== 'Staff Feedback' ){return}
      this.render()
      Font.initFont() 
    }catch(err){
      Toast.show(err, 'error')
    }
  }


  render(){
    const template = html`
      <div class="main-content">
        <div class="content-panel">    
          <main-app-header pagename="${document.title}"></main-app-header>

          <!-- Version 1 = map feedback only -->
          ${this.feedback == null ? html`
            <h2>Getting staff feedback ... </h2> 
            <br><br>
            <c-spinner spinnerSize="sp-sml"></c-spinner>

          ` : html`

            <!-- Version 2 = add tags etc  
            <p class="app-info para">
              These button functions are not yet working.     
            </p> -->

          ${this.feedback.map(item => html `
            
            <div class="form-feedback"> 

              ${item.subject? html`
                <div class="input-group">
                  <input id="subject" class="para" value="${item.subject}">
                </div>
                `:html``}

                <div class="input-group">
                  <textarea class="para" rows="3" cols="50" name="feedback" value="${item.feedback}">${item.feedback}</textarea>
                </div>

                <div class="input-group">
                  <select class="para" name="tag" id="tag">
                    <option value="placeholder" disabled selected hidden>Tag feedback...</option>
                    <option value="testimony">Testimony</option>
                    <option value="complaint">Complaint</option>
                    <option value="suggestion">Suggestion</option>
                  </select>
                </div>
                <div class="two-buttons">
                  <a id="${item._id}" class="mod-button action" @click=${this.updateHandler}>Update</a>
                  <a id="${item._id}" class="mod-button warning" @click=${this.confirmDelete}>Delete</a>
                </div>
              </div>
            `)}

          `}

        </div>  
      </div> 
    `

    render(template, App.rootEl)
  }
}

export default new ViewfeedbackView()