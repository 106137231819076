import App from '../../App'
import {render, html} from 'lit/html.js'
import SAuth from '../../SAuth'
import Validate from '../../functions/Validate'
import StaffApi from '../../api/staff/StaffApi'
import Font from '../../functions/Font'


class SettingsView {
  init(){    
    document.title = 'Settings'    
    this.render()  
    Font.initFont() 
  }

  enlargeClick(e){
    e.preventDefault()
    const ps = document.querySelectorAll('.para')
    Font.upFont()
    ps.forEach(p => {
      p.style.fontSize = Font.updatedfont
    })
    Validate.removeWarn('font')
  }
  
  downClick(e){
    e.preventDefault()
    const ps = document.querySelectorAll('.para')
    Font.downFont()
    ps.forEach(p => {
      p.style.fontSize = Font.updatedfont
    })
    Validate.removeWarn('font')
  }

  async saveMyFontHandler(){
    if(Font.myfont == SAuth.currentUser.fontPref){
      Validate.inputWarn('font', "*Font has not changed")
      return
    }
    let userFont = {}
    userFont.fontPref = Font.myfont
    const mainTab = document.getElementById('main-tab')
    const statusTab = document.getElementById('status-tab')
    mainTab.classList.add('hidden')
    statusTab.innerHTML = '<c-waiting waitSource="settings"></c-waiting>'
    try {
      const userObject = JSON.stringify(userFont);
      const updated = await StaffApi.updateUser(SAuth.currentUser._id, userObject)
      if(updated == false){
        statusTab.innerHTML = '<c-error errorSource="goback"></c-error>'
      } else { 
        statusTab.innerHTML = '<c-success></c-success>'
      }
    }catch(err){
      console.log('Problem updating profile')
      statusTab.innerHTML = '<c-error errorSource="goback"></c-error>'
    }
  }


  


  render(){
    const template = html`
      
      <div class="main-content">

        <main-app-header pagename="${document.title}"></main-app-header>

        <div class="content-panel">   

          <div class="h-center"><P class="sett"><strong>Font Size</strong></P></div> 
              
          <div class="redirect-button action sets" @click="${this.enlargeClick}">Larger  +</div>
          <div class="redirect-button action sets" @click="${this.downClick}">Smaller  -</div>
                        
          <div class="h-center parabox">
            <p class="para" >
              How large would you like the paragraph text?
            </p>
          </div>

          <div id="main-tab" class="h-center">
            <br>
            ${localStorage.accessToken == null? html`
              <p>*Log in to save and activate your preferences.</p>
              <a class="forum-button disabled"> Save font size </a>
            `:html`
              <a class="forum-button forum-btn primary" @click=${this.saveMyFontHandler}>
                Save font size
              </a>
            `}
            <p id="font-wrn" class="input-require para">*Font has not changed</p>
          </div>
          <div id="status-tab"></div>
              
        </div> 
     </div> 
    `

    render(template, App.rootEl)
  }
}

export default new SettingsView()