import App from '../../../App'
import {render, html} from 'lit/html.js'
import {gotoRoute} from '../../../Router'
import SAuth from '../../../SAuth'
import ThreadAPI from '../../../api/ThreadAPI'
import Moderate from '../../../functions/Moderate'
import Font from '../../../functions/Font'

class ModCheckThreadView {
  init(){    
    document.title = 'Check Threads'    
    this.render()  
    this.initCheckThreads()
    Font.initFont() 
  }

  async initCheckThreads(){
    if(Moderate.modThreads !== null){ this.render(); return }
    if(Moderate.modGroup == null){
      const urlParams = new URLSearchParams(location.search)
      const testText = urlParams.get('section')
      if(testText !== 'peach' || testText !== 'azure' || testText !== 'forrest' ){ gotoRoute('/'); return }
      Moderate.modGroup = testText
    }
    const mainTab = document.getElementById('main-tab')
    const statusTab = document.getElementById('status-tab')
    try{
      Moderate.modThreads = await ThreadAPI.getUnapprovedThreads(Moderate.modGroup)
      if(document.title !== 'Check Threads' ){return}
      this.render()
    }catch(err){
      console.log(err)
      mainTab.classList.add('hidden')
      statusTab.innerHTML = '<c-error errorSource="mod-menu"></c-error>'
    }
  }

  render(){
    const template = html`
      <div class="main-content">
        <main-app-header pagename="${document.title}"></main-app-header>
        <div class="content-panel">  
          <div id="main-tab">   
            ${Moderate.modThreads == null ? html`
              <br> <br>Getting threads ...<br> <br>
              <div class="spinner sp-sml"></div> 
            `:html`
              ${Moderate.modThreads.length == 0 ? html`
                <br><br>
                <p class="welcome-text"> <strong>Welcome ${SAuth.currentUser.firstName}! </strong> </p>
                <p class="welcome-text"> No threads to check here.  </p>
              `:html`
                ${Moderate.modThreads.map(thread => html ` 
                  <mod-thread thread="${JSON.stringify(thread)}" 
                  user="${JSON.stringify(thread.user)}" group="${Moderate.modGroup}"></mod-thread>
                `)} 
              `} 
            `}

            <forum-btn btnType="mod-main"></forum-btn> 
          </div>
          <div id="status-tab"></div>
        </div>  
      </div> 
    `
    render(template, App.rootEl)
  }
}

export default new ModCheckThreadView()