import App from '../../../App'
import {render, html} from 'lit/html.js'
import '../../../components/ps-home-btn'
import {checkw, pencilw, addmodw} from '../../../data/Images'


class AdminMainView {
  init(){    
    document.title = 'Admin Menu'    
    this.render()  
  }


  render(){
    const template = html`
      <div class="main-content">
        <div class="content-panel">    
          <main-app-header pagename="${document.title}"></main-app-header>
          
          <p class="app-info para">Admin Access Areas</p>

          <ps-home-btn group="primary" imageName="${pencilw}"pageLink="/admin/feedback">User Feedback</ps-home-btn>
          <ps-home-btn group="primary" imageName="${pencilw}"pageLink="/admin/stafffeedback">Staff Feedback</ps-home-btn>
          <ps-home-btn group="primary" imageName="${addmodw}"pageLink="/admin/newmod">Add New Mod</ps-home-btn>
          <ps-home-btn group="primary" imageName="${checkw}"pageLink="/admin/mods">Moderators</ps-home-btn>

        </div>  
      </div> 
    `

    render(template, App.rootEl)
  }
}

export default new AdminMainView()