import {LitElement, html} from 'lit'
import {gotoRoute} from '../../Router'

customElements.define('cmi-submit', class AppHeader extends LitElement {
  constructor(){
    super()    
  }

  static get properties(){
    return {
      modalSource: { type: String },
      modalType: { type: String }
    }
  }

  firstUpdated(){   
    super.firstUpdated()
  }

  render(){    
    return html`

      ${this.modalSource == "feedback" ? html`
        ${this.modalType == "wait" ? html`
          <c-waiting waitSource="feedback"></c-waiting>
        ` : html``}
        ${this.modalType == "success" ? html`
          <c-success successSource="feedback"></c-success>
        ` : html``}
        ${this.modalType == "error" ? html`
          <c-error errorSource="close-modal"></c-error>
        ` : html``}
      ` : html``}

    `
  }
  
  
})
