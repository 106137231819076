import App from '../../../App'
import {render, html} from 'lit/html.js'
import '../../../components/ps-home-btn'
import {checkw, pencilw, addmodw} from '../../../data/Images'


class ViewmodsView {
  init(){    
    document.title = 'Moderators'    
    this.render()  
  }

  render(){
    const template = html`
      <div class="main-content">
        <div class="content-panel">    
          <main-app-header pagename="${document.title}"></main-app-header>
          <ps-home-btn group="primary" imageName="${addmodw}"pageLink="/admin/newmod">Add New Mod</ps-home-btn>

          <!-- <p class="app-info para">
            Moderators List and relevant functions to go here.       
          </p> -->

        </div>  
      </div> 
    `

    render(template, App.rootEl)
  }
}

export default new ViewmodsView()