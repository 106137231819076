import SAuth from '../SAuth';


class Modal {

    constructor(){

      this.notice = null
      this.modalDiv = document.getElementById('modal-div')


    }  

    modalNotice(){
      this.modalDiv.innerHTML = `<main-modal modalClass="notice" modalInfo="notice"></main-modal>` 
    }
  
    //------Confirmation Modal Links ------------class = mi-name----------------------------------
    cmodalNoChange(){
      this.modalDiv.innerHTML = `<confirm-modal modalClass="editcasewarn" modalInfo="nochanges"></confirm-modal>` 
    }
  
    confirmModal(modalClass, modalSource, modalInfo){
      this.modalDiv.innerHTML = `<confirm-modal modalClass="${modalClass}" modalSource="${modalSource}" modalInfo="${modalInfo}"></confirm-modal>`
    }
  
    closeModal(){ this.modalDiv.innerHTML = `` }

  
  }
  
  export default new Modal()