import App from '../../../App'
import {render, html} from 'lit/html.js'
import {gotoRoute} from '../../../Router'
import PostAPI from '../../../api/PostAPI'
import ThreadAPI from '../../../api/ThreadAPI'
import Moderate from '../../../functions/Moderate'
import Format from '../../../functions/Format'
import Font from '../../../functions/Font'


class ModViewThreadView {
  init(){    
    document.title = 'View Thread'  
    this.thread = null
    this.posts = null  
    this.render()  
    this.initPage()
    Font.initFont() 
  }

  initPage(){
    if(!Moderate.viewthreadId){gotoRoute('/mod/checkposts'); return }
    this.getThreadandPosts()
  }

  async getThreadandPosts(){

    try{
      this.thread = await ThreadAPI.getThread(Moderate.modGroup, Moderate.viewthreadId)
      this.posts = await PostAPI.getPostbyThreadId(Moderate.modGroup, Moderate.viewthreadId)
      if(document.title !== 'View Thread' ){return}
      this.render()
      Font.fontUpdate()
      this.threadUpdate()
      Moderate.myThread = this.thread
    }catch(err){
      console.log(err)
      //mainTab.classList.add('hidden')
      //statusTab.innerHTML = '<c-error errorSource="mod-posts"></c-error>'
    }
  }

  threadUpdate(){
    const tTextDiv = document.getElementById('thread-text-formatted')
    const formattedText = Format.formatParagraphText(this.thread.threadText)
    tTextDiv.innerHTML = formattedText
  }

  render(){
    const template = html`
      <div class="main-content">

        <div class="content-panel">    
          <main-app-header pagename="${document.title}"></main-app-header>
          
          ${this.thread == null ? html``:html`
            <div class="forum-thread-title">${this.thread.threadTitle}</div>                    
            <div class="thread-title-wrap">
              <div class="thread-title-name"> ${this.thread.user.userName} </div>
              <div class="thread-title-date">  ${new Date(this.thread.createdAt).toLocaleDateString()} </div>
              <p id="thread-text-formatted" class="thread-title-text para"></p>

            </div >  
          `}  

          ${this.posts == null? html`
            Getting Posts
            <br><div class="spinner sp-sml"></div> 
          `:html`
            ${this.posts.map(post => html`
            
              ${post.approved == true ? html`
                <forum-post 
                  post="${JSON.stringify(post)}"
                  typeInfo="mod">
                </forum-post>
              `:html`
                <mod-post 
                  post="${JSON.stringify(post)}" 
                  group="${Moderate.modGroup}">
                </mod-post>
              `}
            
            `)}

          `}

        </div>  
      </div> 
    `

    render(template, App.rootEl)
  }
}

export default new ModViewThreadView()