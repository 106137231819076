import {LitElement, html} from 'lit'
import {anchorRoute, gotoRoute} from '../Router'
import SAuth from '../SAuth' 
import App from '../App'
import {myLogo} from '../data/Images'
//${myLogo}
customElements.define('main-app-header', class AppHeader extends LitElement {
  constructor(){
    super()    
  }

  static get properties(){
    return {
      pagename: { type: String }
    }
  }

  firstUpdated(){
    super.firstUpdated()
    this.navActiveLinks()
    this.addImages()
  }

  addImages(){
    const logoImage = this.shadowRoot.getElementById('icon-holder')
    logoImage.innerHTML = myLogo
  }

  navActiveLinks(){	
    const currentPath = window.location.pathname
    const navLinks = this.shadowRoot.querySelectorAll('.app-top-nav a, .app-side-menu-items a')
    navLinks.forEach(navLink => {
      if(navLink.href.slice(-1) == '#') return
      if(navLink.pathname === currentPath){			
        navLink.classList.add('active')
      }
    })
  }

  hamburgerClick(){  
    const appMenu = this.shadowRoot.getElementById('ps-drawer')
    const holder = this.shadowRoot.getElementById('drawer-backdrop-holder')
    const backdrop = this.shadowRoot.getElementById('drawer-backdrop')
    holder.style.width = "100%"
    backdrop.style.backgroundColor = "rgb(0,0,0,0.5)"
    appMenu.style.left = "0"
  }


  closeMenuClick(){  
    const appMenu = this.shadowRoot.getElementById('ps-drawer')
    const holder = this.shadowRoot.getElementById('drawer-backdrop-holder')
    const backdrop = this.shadowRoot.getElementById('drawer-backdrop')
    appMenu.style.left = "-100%"
    backdrop.style.backgroundColor = "rgb(0,0,0,0.0)"
    // wait half a second
        setTimeout(function () {
          holder.style.width = "0"
      }, 500);
  }
  
  menuClick(e){
    e.preventDefault()
    const currentPath = window.location.pathname
    const pathname = e.target.closest('a').pathname
    if(currentPath == pathname){return}
    else { 
      //previous isMobile() code
      let viewportWidth = window.innerWidth
      if(viewportWidth <= 768){ this.closeMenuClick() }
      gotoRoute(pathname)
    }
  }

  signOutHandler(e){
    e.preventDefault()
    SAuth.signOut()
  }


//          height: var(--app-header-height);
  render(){    
    return html`
      <style>      
        * { box-sizing: border-box; margin: 0px; padding: 0px; }

        .app-header {
          position: fixed;
          top: 0;
          right: 0;
          left: 0;
          height: 60px; 
          color: var(--three-color);
          z-index: 15;
        }
        
        #burger-div { width: 19vw; align-items: center; display: inline-block; }
        #title-div { border-bottom: 1px solid var(--two-color);  width: 60vw; 
          padding: 0px; display: inline-block;  height: 100%; }
        h1 { padding-top: 5px; height: 100%; 
          font-family: 'bree', Cambria, Georgia, Times, 'Times New Roman', serif, sans-serif; 
          display: flex; align-items: center; justify-content: left; }

        #burger-icon { width: 40px; cursor: pointer; top: 15px; position: absolute;  left: 8vw; }
        .page-title { color: var(--app-header-txt-color);  font-size: var(--app-header-title-font-size); }
        .logo-home { transition: 0.6s ease-in-out; width: 50px; top: 10px; position: absolute;  right: 8vw; }

        .logo-home:hover { transform: rotate(20deg);}

        #drawer-backdrop-holder{
          position: absolute;
          top: 0;
          left: 0;
          width: 0;  
          height: 100vh;
          z-index: 10;
        }
        #drawer-backdrop{
          position: relative;
          width: 100%;  
          height: 100%;
          background-color: rgb(0,0,0,0.0);
          transition: background-color .5s ease-out;
        }

        #ps-drawer {
          position: absolute;
          z-index: 999;
          top: 0;
          height: 100vh;
          width: 50vw;
          background-color: white;
          box-shadow: 4px 0px 5px rgba(0,0,0,0.3);
          padding: 0 5vw;
          transition: .5s ease-out;
          left: -100%;
        }

        #nav-wrapper {
          position: absolute;
          top: 0;
          left: 0;
          padding:  3vh 3vh;
          width: 100%;
          height: 100vh;
          overflow: auto;
        }

        #drawer-header { width: 100%; }

        .close-drawer {
          width: 25px;
          position: absolute;
          cursor: pointer;
          right: 15px;
          top: 15px;
        }

        .ltext-icon { width: 150px; margin: auto; }

        .app-side-menu-items { display: block; }
        .app-side-menu-items a {
          display: block;
          padding: .5em;
          text-decoration: none;
          font-size: 1em;
          color: var(--three-color);
          text-align: left;
        } 
        .app-side-menu-items a:hover { color: var(--two-color); }

     /* active nav links */
        .app-side-menu-items a.active {
          font-weight: bold;
          border: 1px solid var(--two-color);
        }

        .disabled { color: #5c5b5b;  cursor: pointer;
          padding: 0em  .5em  .5em .5em ; 
          font-size: 0.8em; }
        .disabled:hover {color: #3c3b3b;}

        ::-webkit-scrollbar {width: 15px;}
        ::-webkit-scrollbar-track { background: var(--bginfo-color); border-radius: 500px; }
        ::-webkit-scrollbar-thumb { background: var(--brand-color); border-radius: 500px; }
        ::-webkit-scrollbar-thumb:hover { background: var(--two-color); }


        @media all and (max-width: 768px){   
          
          .app-header { height: 60px; }
          .page-title { font-size: 1.7em; }
          #nav-wrapper { padding:  1vh 3vh; overflow: auto; }
          .mobile-hide { display: none; }
          #drawer-header { width: 100%; margin-top: 20px;}
          .app-side-menu-items {
            margin-top: 15px;
          }

        }

        @media all and (max-width: 500px){ #ps-drawer { width: 80vw; } }

        @media all and (max-width: 450px){ 
          #burger-div { align-items: center; }
          #burger-icon { left: 6vw; top: 17px; }
          .logo-home {  right: 6vw; }
        }

        @media all and (max-width: 350px){ 
          #title-div { font-size: .8em; height: 90%; }
          .logo-home {  right: 4vw; width: 40px; }
          #burger-icon {  left: 4vw; width: 30px; }
          #burger-div, #logo-div { width: 15vw; }


        }

        @media all and (min-width: 769px){  

          .feedback-mobile { display: none; }
          .desktop-hide { display: none; }
          #burger-div { display: none; }
          #title-div { margin-left: 30vw; width: 47vw; align-items: left; justify-content: left;}
          .logo-home { width: 80px;  }
          .ltext-icon { width: 150px; margin: auto; }

          #ps-drawer {
            position: fixed;
            width: 25vw;
            height: 100%;
            bottom: 0;
            left: 0;
            padding: 0;
            overflow: hidden;
          }

          #side-banner  {
            min-width: 25vw;
            min-height: 100vh;
            pointer-events: none;
          }
        }

      </style>

      <header class="app-header">
        <div id="burger-div" class="header-items"> 
          <!-- <img id="burger-icon" class="desktop-hide" alt="Menu" @click="${this.hamburgerClick}" src="https://hashtag-metoo.com/images/icons/${App.appcode}04-menu.svg"> -->
          <nav id="burger-icon" class="desktop-hide" alt="Menu" @click="${this.hamburgerClick}">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.1809 19.7029"><defs><style>.cls-1{fill:#00afc6;}</style></defs><g><rect class="cls-1" width="24.1809" height="4.4116" rx="2.2058"/><rect class="cls-1" y="7.6457" width="24.1809" height="4.4116" rx="2.2058"/><rect class="cls-1" y="15.2913" width="24.1809" height="4.4116" rx="2.2058"/></g></svg>
          </nav>
        </div>

        <div id="title-div" class="header-items">
          <h1 class="page-title">${this.pagename ? html`${this.pagename}`:``}</h1>
        </div>
        <div id="logo-div" class="header-items">
          <a href="/" @click="${anchorRoute}" title="Home Link" class="logo-home" id="icon-holder">
          <!-- <img class="logo-home" alt="Home Icon" src="https://hashtag-metoo.com/images/icons/1-logo-${App.appcode}.svg"> -->
        </a>
        </div>

        <div id="drawer-backdrop-holder"><div id="drawer-backdrop"></div></div>
        <div id="ps-drawer">

          <img id="side-banner" class="mobile-hide" alt="Side Banner"  src="https://hashtag-metoo.com/images/icons/${App.appcode}-desktop-menu.png">
          <feedback-widget class="feedback-mobile"></feedback-widget>
          <div id="nav-wrapper"> 
            <div id="drawer-header">
              <!-- <img class="close-drawer desktop-hide" alt="Close"  src="https://hashtag-metoo.com/images/icons/${App.appcode}07-close.svg" @click="${this.closeMenuClick}"> -->
              <nav id="close-btn" class="close-drawer desktop-hide" alt="Close" @click="${this.closeMenuClick}">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.2719 11.2597"><defs><style>.cls-1{fill:#00afc6;}</style></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_6" data-name="Layer 6"><path class="cls-1" d="M7.426,5.6238l3.4752-3.4752a1.2659,1.2659,0,0,0,0-1.79,1.2962,1.2962,0,0,0-1.79,0L5.6359,3.834,2.1607.3585a1.2962,1.2962,0,0,0-1.79,0,1.2669,1.2669,0,0,0,0,1.79L3.8461,5.6238.3709,9.0993a1.265,1.265,0,0,0,0,1.79,1.2962,1.2962,0,0,0,1.79,0L5.6359,7.4136l3.4755,3.4755a1.2657,1.2657,0,1,0,1.79-1.79Z"/></g></g></svg>
              </nav>

              <div class="header-items"> 
                <img class="ltext-icon desktop-hide" alt="${App.name}" src="https://hashtag-metoo.com/images/icons/2-apptext-${App.appcode}.svg">
                <img class="ltext-icon mobile-hide" alt="${App.name}" src="https://hashtag-metoo.com/images/icons/${App.appcode}-title-wh.svg">
              </div>

            </div>
            

            <nav class="app-side-menu-items">
              <a href="/" @click="${this.menuClick}">Home</a>


<!-- Moderator & admin access   -->
              ${localStorage.accessToken == null? html`` : html`
                 ${SAuth.currentUser.accessLevel >= 6 ? html`
                  <a href="/admin/main" @click="${this.menuClick}">Admin</a> 
                ` : html``}
              `}

              <a href="/modpolicy" @click="${this.menuClick}">Moderating Policy</a>
              <a href="/settings" @click="${this.menuClick}">Settings</a>
              <a href="/user/profile" @click="${this.menuClick}">Profile</a>
              <a href="#" @click="${this.signOutHandler}">Sign Out</a>
            
            </nav> 
          </div>
        </div>

      </header>

    `
  }
  
})
