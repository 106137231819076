import SAuth from '../SAuth';

const countrydata = require('./../data/countryflags.json');

class Country {

    constructor(){
      this.myCountryCode = null
      this.myCountry = null
      this.allCountries = countrydata
      this.priority = [{ "countryName": "Australia", "countryCode": "aus", "emergNumber": "000", "flag": true },
        { "countryName": "New Zealand", "countryCode": "nzl", "emergNumber": "111", "flag": true },
        { "countryName": "United Kingdom", "countryCode": "gbr", "emergNumber": "999 or 112", "flag": true },
        { "countryName": "United States of America", "countryCode": "usa", "emergNumber": "911", "flag": true},
        { "countryName": "Canada", "countryCode": "can", "emergNumber": "911", "flag": true}]

    }  

//used in profile and edit profile
     initCountry(){
       if(SAuth.currentUser.countryCode){ this.myCountryCode = SAuth.currentUser.countryCode } 
       else { this.myCountryCode = "usa" }
       const countriesobj = this.allCountries 
       const country = countriesobj.find(country => country.countryCode == this.myCountryCode)
       this.myCountry = country
     }

//not used
/*
     updateCountry(newId){
      const countriesobj = this.allCountries 
      const country = countriesobj.find(country => country.countryCode == newId)
      this.myCountry = country
     }
*/
  
  }
  
  export default new Country()