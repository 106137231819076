import {LitElement, html, css} from 'lit'


customElements.define('c-spinner', class Features extends LitElement {

  static get properties(){
    return {
        spinnerSize: { type: String }
    }
  }

  static styles = css`

    * { box-sizing: border-box; margin: 0px; padding: 0px; }

    .spinner{
      border-top: 4px solid var(--brand-color);
      border-right: 4px solid transparent;
      border-radius: 50%;
      animation: rotation .8s linear infinite;
      margin: 20px auto;
      height: 70px; width: 70px;
    }

    @keyframes rotation{
      from{ transform: rotate(0deg); }
      to{ transform: rotate(360deg); }
    }

    .sp-lg { height: 115px; width: 115px; }
    .sp-sml { height: 50px; width: 50px; }
  
  `

  render(){    
    return html`

      <div class="spinner ${this.spinnerSize}"></div>

    `
  }
  
})
