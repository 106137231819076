import {LitElement, html} from 'lit'
import {gotoRoute} from '../../Router'
import SAuth from '../../SAuth' // for user auth
import ThreadAPI from '../../api/ThreadAPI'
import Toast from '../../Toast'
import Moderate from '../../functions/Moderate'
import Format from '../../functions/Format'


customElements.define('mod-thread', class MapThread extends LitElement {
  constructor(){
    super()    
  }

  static get properties(){
    return {
      thread: { type: Object },
      //user: { type: Object },
    }
  }
  

  firstUpdated(){
    super.firstUpdated()
    this.threadUpdate()
  }

  threadUpdate(){
    const tTextDiv = this.shadowRoot.getElementById('thread-text-formatted')
    const formattedText = Format.formatParagraphText(this.thread.threadText)
    tTextDiv.innerHTML = formattedText
  }

  threadActionHandler(){
    Moderate.myThread = this.thread
    gotoRoute('/mod/threadactions')
  }

  async approveHandler(){
    const hidePost = this.shadowRoot.getElementById('mod-thrd')
    hidePost.classList.add('hidden')
    const newThread = {}
    newThread.approved = true
    newThread.moderator = SAuth.currentUser._id
    const threadObject = JSON.stringify(newThread)

    try{
      await ThreadAPI.updateThread(Moderate.modGroup, this.thread._id, threadObject)
      for (let i = 0; i < Moderate.modThreads.length; i++) {
        if (Moderate.modThreads[i]._id == Moderate.myThread._id) {
          Moderate.modThreads.splice(i, 1);
        }
      }
      Toast.show('Thread Approved')
    }catch(err){
      Toast.show(err, 'error')
    }
  }

  

  render(){    
    return html`
    <style>      
    
      * { box-sizing: border-box; margin: 0px; padding: 0px; }

      .forum-thread-title {
        background-color: var(--two-color);
        color: white;
        font-size: 1.2em; 
        padding: 5px;
        text-align: center;
        border-radius: 10px 10px 0 0 ;
      }
      .post-wrap {
        border: solid 2px var(--two-color);
        margin:  0 0 10px 0; 
        padding: 10px;
        border-radius: 0 0 10px 10px ;
      }
      .post-info { color: gray; padding: 5px;}
      p { margin-bottom: 10px; font-size: 1.1em; }
      .two-buttons { width: 100%; display: flex; margin: 5px 0; }
      .primary { background-color: var(--brand-color); }
      .action { background-color: var(--action-color) ; }
      .mod-button{
        width: 50%; 
        padding: 10px;
        border-radius: 500px;
        text-decoration: none;
        cursor: pointer;
        font-size: 1.1em;
        color: white;
        margin: 0 10px;
        text-align: center;
        user-select: none;
      }

      .tick { width: 15px; margin-left: 10px; }
      .hidden { display: none; }


      /* ------------------- RESPONSIVE ------------------- */

      @media all and (max-width: 350px){ 

        .mod-button{
          padding: 5px;
          font-size: 1em;
          margin: 0 5px;
        }
        .tick { width: 10px; margin-left: 5px; }
      
      }




      </style>

      <div id="mod-thrd">
        <div class="forum-thread-title">${this.thread.threadTitle}</div>
        <div class="post-wrap"> 
          <div class="post-info"> 
          ${new Date(this.thread.createdAt).toLocaleDateString()} - ${JSON.stringify(this.thread.user.userName)} </div>
          <p id="thread-text-formatted" class="thread-title-text para"></p>
          <div class="two-buttons">
            <a class="mod-button primary" @click=${this.approveHandler}>Approve
              <img class="tick" alt="tick" src="https://hashtag-metoo.com/images/icons/tick-wh.svg"></a>
            <a class="mod-button action" @click=${this.threadActionHandler}>Actions</a>
          </div>
        </div >
      </div>

    `
  }
  
})
