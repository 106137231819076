import App from '../../../App'
import {render, html} from 'lit/html.js'
import {gotoRoute} from '../../../Router'
import SAuth from '../../../SAuth'
import PostAPI from '../../../api/PostAPI'
import Moderate from '../../../functions/Moderate'
import Font from '../../../functions/Font'

class ModCheckView {
  init(){    
    document.title = 'Moderate Posts'    
    this.render()  
    this.initCheckPosts()
    Font.initFont()
  }

  async initCheckPosts(){
    if(Moderate.modPosts !== null){ this.render(); return }
    if(Moderate.modGroup == null){
      const urlParams = new URLSearchParams(location.search)
      const testText = urlParams.get('section')
      if(testText !== 'peach' || testText !== 'azure' ||testText !== 'forrest' ){ gotoRoute('/'); return }
      Moderate.modGroup = testText
    }
    const mainTab = document.getElementById('main-tab')
    const statusTab = document.getElementById('status-tab')
    try{
      Moderate.modPosts = await PostAPI.getUnapprovedPosts(Moderate.modGroup)
      if(document.title !== 'Moderate Posts' ){return}
      this.render()
    }catch(err){
      console.log(err)
      mainTab.classList.add('hidden')
      statusTab.innerHTML = '<c-error errorSource="mod-menu"></c-error>'
    }
  }



  render(){
    const template = html`
      <div class="main-content">
        <div class="content-panel">    
        <main-app-header pagename="${document.title}"></main-app-header>
          <div id="main-tab">   
            ${Moderate.modPosts == null ? html`
              <br> <br>Getting posts ...<br> <br>
              <div class="spinner sp-sml"></div> 
            `:html`
              ${Moderate.modPosts.length == 0 ? html`
                <br><br>
                <p class="welcome-text"> <strong>Welcome ${SAuth.currentUser.firstName}! </strong> </p>
                <p class="welcome-text"> No posts to check here.  </p>
              `:html`
                ${Moderate.modPosts.map(post => html ` 
                  <mod-post post="${JSON.stringify(post)}" 
                   group="${Moderate.modGroup}"></mod-post>
                `)} 
              `} 
            `}

            <forum-btn btnType="mod-main"></forum-btn> 
          </div>
          <div id="status-tab"></div>
          
        </div>  
      </div> 
    `

    render(template, App.rootEl)
  }
}

export default new ModCheckView()