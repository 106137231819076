import SAuth from '../SAuth';


class Font {

    constructor(){

      this.myfont = 1.2
      this.updatedfont = null

    }  

    
  //add if statement to see if the user has a saved font value
  initFont(){
    //if user && user.fontPref ....
    if (!SAuth.currentUser.fontPref){
      if (this.updatedfont == null ){
        return
      } else {
        this.fontUpdate()
      }
    } else {
      this.myfont = SAuth.currentUser.fontPref
      this.updatedfont = this.myfont + 'em'
      this.fontUpdate()
    }
  }

  fontUpdate(){
    const fontPrefs = document.querySelectorAll('.para')
    fontPrefs.forEach(fontPref => {
      fontPref.style.fontSize = this.myfont +'em';
    })
  }


  // changed sizes are overridden if user has a saved "fontPref"
  //need to add button to save font sizes
  downFont(){
    if (this.myfont <= 0.8) {
      this.myfont = 0.8  
      //console.log(this.myfont + " is too small")
     } else {
      this.myfont -= 0.2 
       const newfont = this.myfont.toFixed(1)
       this.myfont = newfont
       this.updatedfont = this.myfont + 'em'
     }
  }

  upFont(){
    if (this.myfont >= 5) {
      this.myfont = 5  
      //console.log(this.myfont + "is too big")
     } else {
       const newvalue = (+this.myfont)+0.2
       const newfont = newvalue.toFixed(1)
       this.myfont = newfont
       this.updatedfont = this.myfont + 'em'
     }
  }

  
  }
  
  export default new Font()