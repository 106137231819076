//STAFF AUTH
import App from './App'
import Router, { gotoRoute } from './Router'
import splash from './views/partials/splash'
import {html, render } from 'lit/html.js'
import Toast from './Toast'


// ---------------Functions ---------------
    // Sign in
    // userCheck
    // Sign out

// Sign up -- via "RegisterAPI" ------
// Reset Password -- via "ForgoAPI" ---------

class SAuth {

  constructor(){
    this.forumAccess = false
    this.currentUser = {}
    this.myCases = null
    this.caseId = null
    // Compare forumSubExpiry with Auth.thisDay to confirm user forum access
    this.theDate = new Date();
    this.thisDay = this.theDate.getTime()
    //for resetting the password
    this.forgotUser = null
  }

  
  async signIn(userData, fail = false){
    try{
      const response = await fetch(`${App.apiBase}/sauth/signin`, {
        method: 'POST',      
        headers: {"Content-Type" : "application/json", "Origin": App.origin},
        body: userData
      })
      if(!response.ok){// catches any status not between 200-299
        const err = await response.json()
        if(err) console.log(err)
        Toast.show(`Problem signing in: ${err.message}`, 'error')   
        if(typeof fail == 'function') fail()
        return false
      }
      // sign in success
      const data = await response.json()
      Toast.show(`Welcome  ${data.user.firstName}`)
      localStorage.setItem('accessToken', data.accessToken)
      this.currentUser = data.user      
      gotoRoute('/')
      return true
    } catch(err) {
      console.log(err)
      Toast.show(err, 'error')
      return false
    }
  }

  //This function is called when the app starts  
  // --automatic sign in if token is valid
  async userCheck(success){ 
    render(splash, App.rootEl)
    const accessPath = window.location.pathname.split('/')[1];
    if(accessPath == 'token-validation'){
      Router.init()
      return
    } else if (!localStorage.accessToken){ 
      // no local token!
      Toast.show("Please sign in")    
      // redirect to sign in page      
      gotoRoute('/signin')
      return
    }
    try {
      // token must exist - validate token via the backend
      const response = await fetch(`${App.apiBase}/sauth/validate`, {
        method: 'GET',
        headers: { "Authorization": `Bearer ${localStorage.accessToken}` }
      })
      if(!response.ok){             
        const err = await response.json()
        if(err) console.log(err)
        localStorage.removeItem('accessToken')
        Toast.show("session expired, please sign in")
        gotoRoute('/signin')
        return
      }
      // token is valid!
      const data = await response.json()
      this.currentUser = data.user
      Toast.show("Welcome "+ this.currentUser.firstName)   
      success() 
    }
    catch (err) {
      console.log(err)
      Toast.show("Not signed in", 'error') 
      localStorage.removeItem('accessToken')
      gotoRoute('/signin')
      return
    }
  }

  signOut(){
    localStorage.removeItem('accessToken') 
    gotoRoute('/signin')
    Toast.show(`Signed out`)
    this.currentUser = {}
  }
}

export default new SAuth()