import App from '../../../App'
import {render, html} from 'lit/html.js'

class EditmodView {
  init(){    
    document.title = 'Edit Moderator'    
    this.render()  
  }

  render(){
    const template = html`
      <div class="main-content">
        <div class="content-panel">    
          <main-app-header pagename="${document.title}"></main-app-header>
          <p class="app-info para">
            Edit Moderator functions.         
          </p>
        </div>  
      </div> 
    `
    render(template, App.rootEl)
  }
}

export default new EditmodView()