import App from '../../../App'
import {render, html} from 'lit/html.js'
import {gotoRoute, anchorRoute } from '../../../Router'
import SAuth from '../../../SAuth'
import Toast from '../../../Toast'
import Validate from '../../../functions/Validate' 
import PostAPI from '../../../api/PostAPI'
import SubscribeAPI from '../../../api/SubscribeAPI'
import Modal from '../../../functions/Modal'
import Moderate from '../../../functions/Moderate'
import Format from '../../../functions/Format'



//code removing from array not working - #46 & #141
class ModPostActionsView {
  init(){    
    document.title = 'Posts Actions'   
    this.postInit()
    //console.log(Moderate.modpost)
  }

  postInit(){
    if(!Moderate.modpost){  gotoRoute('/mod/checkposts'); return }
    this.render() 
    this.textUpdate()
  }

  textUpdate(){
    const tTextDiv = document.getElementById('postText')
    const formattedText = Format.formatParagraphText(Moderate.modpost.postText, 'textarea')
    tTextDiv.innerHTML = formattedText
  }

  viewThreadHandler(){
    Moderate.viewthreadId = Moderate.modpost.threadId
    gotoRoute('/mod/viewthread')
  }
  

  //NOT WORKING - NOT SENDING....
  async approveHandler(){
    const newPost = {}
    newPost.approved = true
    newPost.moderator = SAuth.currentUser._id
    const postObject = JSON.stringify(newPost)
    const subscribeObject = this.getSubData()
    try{
      SubscribeAPI.emailSubscribers(subscribeObject)  
      await PostAPI.updatePost(Moderate.modGroup, Moderate.modpost._id, postObject)
      Toast.show('Post Approved')
      //not working
      for (let i = 0; i < Moderate.modPosts.length; i++) {
        if (Moderate.modPosts[i]._id == Moderate.modpost._id) {
          Moderate.modPosts.splice(i, 1);
        }
      }
      gotoRoute('/mod/checkposts')
    }catch(err){
      Toast.show(err, 'error')
    }
  }


  getSubData(){
    //console.log(Moderate.modpost)
    let subscribeData = {}
    subscribeData.message = Format.formatParagraphText(Moderate.modpost.postText) // email content
    subscribeData.thisUserEmail = Moderate.modpost.user.email // don't send to this address
    subscribeData.group = Moderate.modGroup // group
    subscribeData.threadId = Moderate.modpost.threadId
    const subscribeObject = JSON.stringify(subscribeData)
    return subscribeObject
  }

  confirmDelete(e){
    e.preventDefault()
    const mainTab = document.getElementById('main-tab')
    const alertTab = document.getElementById('alert-tab')
    mainTab.classList.add('hidden')
    alertTab.classList.remove('hidden') 
  }

  //async
  async updateHandler(){
    let newPost = Moderate.modpost // replace if updated 
    let updatePost = {} //populate and send to databse for update
    let validated = true
    const postInput = document.getElementById("postText")
    // validate
    if(postInput.value == Moderate.modpost.postText){
      Modal.notice = "No changes have been made"
      Modal.modalNotice()
      return
    } 
    if(postInput.value == ''){
      Validate.inputWarn(postInput.id, "*Post required")
      validated = false
    } else {
      const tStatus = Validate.safeCheck(postInput.value)
      if (tStatus == false){
        Validate.inputWarn(postInput.id, "*No special characters")
        validated = false
      } else if (postInput.value.length < 8 || postInput.value.length > 3000 ){
        Validate.inputWarn(postInput.id, "*Enter 8 to 3000 characters")
        validated = false
      } 
    }
    if(validated == false){ return }
    //update post
    const mainTab = document.getElementById('main-tab')
    const statusTab = document.getElementById('status-tab')
    mainTab.classList.add('hidden')
    statusTab.innerHTML = '<c-waiting waitSource="updating"></c-waiting>'
    //set objects
    newPost.postText = JSON.stringify(postInput.value)
    updatePost.postText = JSON.stringify(postInput.value) 
    updatePost.editMod = SAuth.currentUser._id
    try {
      const postData = JSON.stringify(updatePost)
      const udStatus = await PostAPI.updatePost(Moderate.modGroup, Moderate.modpost._id, postData)
      if(udStatus == false){
        statusTab.innerHTML = '<c-error errorSource="goback"></c-error>'
      }
      const objIndex = Moderate.modPosts.findIndex((obj => obj._id == Moderate.modpost._id ))
      Moderate.modPosts[objIndex] = newPost
      Moderate.modpost = newPost
      Toast.show('Post Updated')
      statusTab.innerHTML = ''
      mainTab.classList.remove('hidden')
      this.render()
    } catch(err) {
      console.log(err)
      statusTab.innerHTML = '<c-error errorSource="goback"></c-error>'
    }

  }

  async deleteHandler(){
    const alertTab = document.getElementById('alert-tab')
    const statusTab = document.getElementById('status-tab')
    alertTab.classList.add('hidden')
    statusTab.innerHTML = '<c-waiting waitSource="delete"></c-waiting>' 
    try {
      const status = await PostAPI.deletePost(Moderate.modGroup, Moderate.modpost._id)
      if(status == false){
        statusTab.innerHTML = '<c-error errorSource="goback"></c-error>'
      } else { 
        //following not working
        for (let i = 0; i < Moderate.modPosts.length; i++) {
            if (Moderate.modPosts[i]._id == Moderate.modpost._id) {
              Moderate.modPosts.splice(i, 1);
            }
        }
        gotoRoute('/mod/checkposts')
      }
    }catch(err){
      statusTab.innerHTML = '<c-error errorSource="goback"></c-error>'
    }
  }

  goback(){
    const mainTab = document.getElementById('main-tab')
    const alertTab = document.getElementById('alert-tab')
    mainTab.classList.remove('hidden')
    alertTab.classList.add('hidden')
  }

  render(){
    const template = html`
      
      <div class="main-content">
        <main-app-header pagename="${document.title}"></main-app-header>

        <div class="content-panel"> 
          <div id="main-tab">     

            <a href="/mod/checkposts" class="forum-button forum-btn primary" @click=${anchorRoute}>Back to Posts
              <img class="back-img" alt="back" src=".https://hashtag-metoo.com/images/icons/sw00-back.svg">
            </a>
            
            <div class="h-center">
              <h2>Censor Posts:</h2>
              *Replace censored text with 4 hashtags: ####
              <div class="divide-line"></div>
            </div>

            ${!Moderate.modpost.user? html`
              Waiting for post...
              <br><br>
              <div class="spinner sp-sml"></div> 
            `: html`  
              <p class="app-info para">
                ${new Date(Moderate.modpost.createdAt).toLocaleDateString()} - ${Moderate.modpost.user.userName} 
              </p>
          
              <form class="page-form" method="post">

                <div class="input-group">
                  <p id="postText-wrn" class="input-require">*No special characters & max 3000 </p>
                  <textarea id="postText" class="para" rows="6" cols="50" id="postText"></textarea>
                </div>

                <div class="two-buttons">
                  <a class="mod-button action" @click=${this.updateHandler.bind(this)}>Update</a>
                  <a class="mod-button warning" @click=${this.confirmDelete}>Delete</a>
                </div>
                <div class="two-buttons">
                  <a class="mod-button primary" @click=${this.approveHandler.bind(this)}>Approve</a>
                  <a class="mod-button primary" @click=${this.viewThreadHandler}>View Thread</a>
                </div>
              </form>
            `} 
          
          </div>
          <div id="status-tab"></div>

          <div id="alert-tab" class="hidden">
            <div class="confirm-wrap h-center">
              <p class="para h-center confirm-space">Are you sure you want to remove this post?</p>
              <div id="delete-click" class="redirect-button warning confirm-space" @click=${this.deleteHandler.bind(this)}>Delete</div>
              <div id="back-click" class="redirect-button primary confirm-space" @click=${this.goback}>Cancel</div>
            </div>
          </div>
        </div>  
      </div> 
    `

    render(template, App.rootEl)
  }
}

export default new ModPostActionsView()