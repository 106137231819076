import {LitElement, html} from 'lit'


customElements.define('c-waiting', class Features extends LitElement {
  constructor(){
    super()    
  } 
  static get properties(){
    return {
      waitSource: { type: String },
      waitInfo: { type: String }
    }
  }
  //On update: more efficient to pass in waitText instead of type

  firstUpdated(){
    super.firstUpdated()
  }


  render(){    
    return html`
      <style>      
        * { box-sizing: border-box; margin: 0px; padding: 0px; }
        .outer-box { text-align: center; }
        .mi-text { margin: 30px auto;  width: 100%; font-size: 1.5em; }
      </style>

      <div class="outer-box">

        ${this.waitSource == "forum-post" ? html`
          <p class="mi-text"> Creating post ...</p>
        ` : html``}
        ${this.waitSource == "forum-thread" ? html`
          <p class="mi-text"> Creating thread ...</p>
        ` : html``}

        ${this.waitSource == "updating" ? html`
          <p class="mi-text"> Updating ...</p>
        ` : html``}
        ${this.waitSource == "delete" ? html`
          <p class="mi-text"> Deleting ...</p>
        ` : html``}
        ${this.waitSource == "feedback" ? html`
          <p class="mi-text"> Sending feedback ...</p>
        ` : html``}
        ${this.waitSource == "settings" ? html`
          <p class="mi-text"> Updating settings ...</p>
        ` : html``}

        ${this.waitSource == "profile" ? html`
          <p class="mi-text"> Updating profile ...</p>
        ` : html``}
        ${this.waitSource == "reset" ? html`
          <p class="mi-text"> Updating password ...</p>
        ` : html``}

        ${this.waitSource == "signin" ? html`
          <p class="mi-text"> Signing in ...</p>
        ` : html``}

        ${this.waitSource == "forgot" ? html`
          <p class="mi-text"> Sending reset link ...</p>
        ` : html``}

        ${this.waitSource == "update" ? html`
          <p class="mi-text"> Sending update link ...</p>
        ` : html``}

        ${this.waitSource == "validating" ? html`
          <p class="mi-text"> Validating email token ...</p>
        ` : html``}
        
        ${this.waitSource == "newmod" ? html`
          <p class="mi-text"> Creating new moderator ...</p>
        ` : html``}

        <br><br>
        <c-spinner spinnerSize="sp-sml"></c-spinner>
      </div>
    `
  }
  
})
