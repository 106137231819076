class Format {
  //possibly also add date format here if needed
  //formatParagraphText(text, type)
  //

    constructor(){

      this.notice = null

    }  

    formatParagraphText(text, type){
      //1) remove the quotation marks
      let breaker = null
      if(type == 'textarea'){ breaker = '&#13;&#10;' } 
      else if(type == 'reply'){ breaker = ' ' } 
      else { breaker = '<br>' } 
      const pTextRefine = text.slice(1, -1); 
      //2) split into array
      const pParts = pTextRefine.split("\\")
      //3) create the final entry
      let finalEntry = null
      if(pParts.length > 1){
        pParts.forEach((part, i) =>{
          if(i == 0){
            finalEntry = part  + breaker
          } else if( i > 0  && i < pParts.length-1){
            const newpart = part.substring(1)
            finalEntry += newpart + breaker
          } else if(i == pParts.length-1) {
            const newpart = part.substring(1)
            finalEntry += newpart
          }
        })
      } else {
       //here there was only one string so display as is
        finalEntry = pParts
      }
      return finalEntry
   }

  }
  
  export default new Format()