import Country from './country'

class Select {

  constructor(){

    //My Custom Select
    this.mscMenu = null
    this.mscInput = null

  }  

    //--- CUSTOM SELECT FUNCTIONS -----------
    mcsOpenMenu(e){
      e.preventDefault()
      this.mscInput = e.target.closest('select')
      if (this.mscInput.disabled == true){
        // if(this.mscInput.id == 'caseType'){
        //   this.inputWarn('caseFrequency')
        // }
        // if(this.mscInput.id == 'perpPosition'){
        //   this.inputWarn('caseLocation')
        // }
        return
      }
      this.removeWarn(this.mscInput.id)
      this.mscMenu = e.target.parentNode.nextElementSibling
      //const myOptions = this.mscMenu.querySelectorAll('li')
      this.mscMenu.classList.remove('hidden')
      const myMenu = this.mscMenu
      const myDoc = document
      myDoc.addEventListener('click', function abc() {
        myMenu.classList.add('hidden')
        myDoc.removeEventListener('click', abc, true)
      }, true)
    }

    mcsSelectOption(e){
      const selected = e.target
      const newText = e.target.innerText
      // remove class from all other items
      if(selected.classList.contains('msc-selected')){
        return
      }else{
        const parent = selected.parentElement.parentElement.id
        const children = document.getElementById(parent).querySelectorAll("p")
        children.forEach(child => {
          if(child.classList.contains('msc-selected'))
          child.classList.remove('msc-selected')
        })
        selected.classList.add('msc-selected')
      }
      this.mscInput.options[0].text = newText
      this.mscInput.options[0].value = newText
      this.dynamicData(e)
    }


    dynamicData(){
      if(this.mscInput.id == "caseCountry"){
        const ccValue = this.mscInput.options[0].value
        const myCountry = Country.allCountries.find(field => field.countryName == ccValue )
        const statesDiv = document.getElementById('states')
        this.resetSelect('caseState')
        if(myCountry.state == null){
          if(!statesDiv.classList.contains('hidden')){
            statesDiv.classList.add('hidden')
          }
        } else {
          this.populateOptions('caseState-l', myCountry.state)
          if(statesDiv.classList.contains('hidden')){
            statesDiv.classList.remove('hidden')
          }
        }
      }// END CASE COUNTRY CONDITIONS
    }// END dynamicData function

    populateOptions(id, array){
      const Ul = document.getElementById(id)
      Ul.innerHTML = ''
      array.forEach(option => {
        const liBtn = document.createElement('li')
        const pBtn = document.createElement('p')
        pBtn.innerText = option
        liBtn.appendChild(pBtn)
        Ul.appendChild(liBtn)
        pBtn.onclick = this.mcsSelectOption.bind(this)
      })  
    }

    inputWarn(id){
      const thisWarn = document.getElementById(id + '-wrn')
      thisWarn.style.maxHeight = Math.max(thisWarn.scrollHeight) + "px"
    }
  
    removeWarn(id){
      const thisWarn = document.getElementById(id + '-wrn')
      if(thisWarn.style.maxHeight == null){return}
      else{thisWarn.style.maxHeight = null}
    }

    preventSelect(e){ // prevent default select menu
      e.preventDefault()
    }

    populateOptions(id, array){
      const Ul = document.getElementById(id)
      Ul.innerHTML = ''
      array.forEach(option => {
        const liBtn = document.createElement('li')
        const pBtn = document.createElement('p')
        pBtn.innerText = option
        liBtn.appendChild(pBtn)
        Ul.appendChild(liBtn)
        pBtn.onclick = this.mcsSelectOption.bind(this)
      })  
    }

    resetSelect(id){
      const reSelect = document.getElementById(id)
      reSelect.options[0].text = 'Select ... '
      reSelect.options[0].value = 'Select ... '
    }
  



}

export default new Select()