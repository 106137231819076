//STAFF SIGN

import SAuth from './SAuth'
import Validate from './functions/Validate'
import ForgotApi from './api/staff/SForgotApi'


// ---CONTENTS---
// event listener functions
// sign in handler
// send forgot link

// Needed to add this here because of the event Listeners
// need to use a global RESET function for when the user navigates away.
// ELSE will end up with conflicting functions.
// Can possibly refactor this page after event functions in place 
  //add eventListeners to events
  //and return the main code to the signin page (don't forget the reset page too)


class Sign {

  constructor(){
    
    this.signEnter = (e) => {this.enterBtn(e)}
    
  }

  //signin enter btn 
  enterBtn(e){
    const mainTab = document.getElementById('main-tab')
    if (e.key === 'Enter' && !mainTab.classList.contains('hidden')) {
      const signView = document.getElementById('sign-div')
      if(!signView.classList.contains('hidden')){
        this.signInSubmitHandler()
      } else {
        this.sendLinkHandler()
      }
      
    }
  }

  async signInSubmitHandler(){
    const userSign = {}
    let signReady = true
    const ueinput = document.getElementById("user-email")
    const pwinput = document.getElementById("password") 
    if(ueinput.value == ""){
      Validate.inputWarn(ueinput.id, "*Email required")
      signReady = false
    } else {
      const ueStatus = Validate.validEmail(ueinput.value)
      if(ueStatus == false){
        Validate.inputWarn(ueinput.id, "*Valid email required")
        signReady = false
      } else { userSign.email = ueinput.value }
    }
    if(pwinput.value == ""){
      Validate.inputWarn(pwinput.id, "*Password required")
      signReady = false
    } else {
      const pwStatus = Validate.passwordCheck(pwinput.value)
      if(pwStatus == false){
        Validate.inputWarn(pwinput.id, "*Valid password required")
        signReady = false
      } else { userSign.password = pwinput.value }
    }
    if(signReady == false){ return }

    const mainTab = document.getElementById('main-tab')
    const statusTab = document.getElementById('status-tab')
    mainTab.classList.add('hidden')
    statusTab.innerHTML = '<c-waiting waitSource="signin"></c-waiting>'
    
    document.removeEventListener('keyup', this.signEnter) 
    

    const userObject = JSON.stringify(userSign)
    const signedIn = await SAuth.signIn(userObject)
    if(signedIn == true){ return }
    else {
      statusTab.innerHTML = '<c-error errorSource="goback" errorInfo="enterEvent"></c-error>'
    }
  }


  //send forgot email link
  async sendLinkHandler(){
    const ueinput = document.getElementById("user-email")
    const user = {}
    if(ueinput.value == ""){
      Validate.inputWarn(ueinput.id, "*Email required")
      return
    } else {
      const ueStatus = Validate.validEmail(ueinput.value)
      if(ueStatus == false){
        Validate.inputWarn(ueinput.id, "*Valid email required")
        return
      } 
    }

    const mainTab = document.getElementById('main-tab')
    const statusTab = document.getElementById('status-tab')
    mainTab.classList.add('hidden')
    statusTab.innerHTML = '<c-waiting waitSource="forgot"></c-waiting>'
    
    document.removeEventListener('keyup', this.signEnter) 
    
    user.email = ueinput.value
    const email = JSON.stringify(user)
    const forgotEmail = await ForgotApi.forgotLink(email)
    //console.log(forgotEmail)

    if(forgotEmail == true){ 
      statusTab.innerHTML = '<c-success successSource="linksent"></c-success>'
     } else {
      statusTab.innerHTML = '<c-error errorSource="goback"></c-error>'
    }
  }


}

export default new Sign()