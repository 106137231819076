class Moderate {

  constructor(){
    this.viewthreadId = null
    this.postAction = null
    this.modpost = null // here put the whole post for access
    this.modGroup = null
    this.modThreads = null // here put all threads for quick access
    this.modPosts = null
    this.myThread = {} 
  }  

}

export default new Moderate()