import App from '../../../App'
import {render, html} from 'lit/html.js'
import Validate from '../../../functions/Validate'
import Select from '../../../functions/Select'
import Country from '../../../functions/Country'
import Font from '../../../functions/Font'
import Scroll from '../../../functions/Scroll'
import Validate from '../../../functions/Validate'
import StaffApi from '../../../api/staff/StaffApi'

//UX - send new mod email to reset their password
//autofill password with a date stamp?

class NewmodView {
  init(){    
    document.title = 'Add Moderator'    
    this.userData = {}
    this.render()  
    Font.initFont() 
    this.enterHandler = (e) => {this.enterBtn(e)}
    document.addEventListener('keyup', this.enterHandler)
  }

  enterBtn(e){
    const mainTab = document.getElementById('main-tab')
    if(document.title !== 'Add Moderator' ){
      document.removeEventListener('keyup', this.enterHandler)
      return 
    }
    if (e.key === 'Enter' && !mainTab.classList.contains('hidden')) {
      this.andModHandler()
    }
  }


  async andModHandler(){
    const mainTab = document.getElementById('main-tab')
    const statusTab = document.getElementById('status-tab')
    const formstatus = this.validateForm()
    if(formstatus == false){ return }

    //else process
    else {
      mainTab.classList.add('hidden')
      statusTab.innerHTML = '<c-waiting waitSource="register"></c-waiting>'
      document.removeEventListener('keyup', this.enterHandler)
      try {
        const newUser = JSON.stringify(this.userData);
        const registered = await StaffApi.addNewStaff(newUser)
        if(registered == false){
          //UX - make this a back component so user can try again without needing to re-enter all information
          statusTab.innerHTML = '<br><br><br><c-error errorSource="back"></c-error>'
        } else { 
          //success
          statusTab.innerHTML = '<c-success successSource="new-mod"></c-success>'
          //Modal.confirmModal('submit','feedback', 'success')
         }
      }catch(err){
        statusTab.innerHTML = '<br><br><br><c-error errorSource="admin-menu"></c-error>'
      }
    }
  }


  validateForm(){
    console.log('validating form')
    Scroll.warnItems = []
    const firstNameInput = document.getElementById("first-name")
    const lastNameInput = document.getElementById("last-name")
    const emailInput = document.getElementById("user-email")

    const pwinput = document.getElementById("password")
    const coinput = document.getElementById("caseCountry")
    const steinput = document.getElementById("caseState")

    const tfninput = document.getElementById("tfn")
    const dobinput = document.getElementById("dob")

    let validated = true


    if(firstNameInput == ''){
      Validate.inputWarn(firstNameInput.id, "* Required")
      Scroll.warnItems.push(firstNameInput.id)
      validated = false
    } else {  
      const fnStatus = Validate.validator('name', firstNameInput.value)
      if(fnStatus == false){
        Validate.inputWarn(firstNameInput.id, "* Letters only")
        Scroll.warnItems.push(firstNameInput.id)
        validated = false
      }
    }

    if(lastNameInput== ''){
      Validate.inputWarn(lastNameInput.id, "* Required")
      Scroll.warnItems.push(lastNameInput.id)
      validated = false
    } else {  
      const lnStatus = Validate.validator('name', lastNameInput.value)
      if(lnStatus == false){
        Validate.inputWarn(lastNameInput.id, "* Letters only")
        Scroll.warnItems.push(lastNameInput.id)
        validated = false
      }
    }

    if(emailInput== ''){
      Validate.inputWarn(emailInput.id, "* Required")
      Scroll.warnItems.push(emailInput.id)
      validated = false
    } else {  
      const lnStatus = Validate.validator('email', emailInput.value)
      if(lnStatus == false){
        Validate.inputWarn(emailInput.id, "* Email format incorrect")
        Scroll.warnItems.push(emailInput.id)
        validated = false
      }
    }

    if(pwinput== '' || pwinput.value.length < 6 || pwinput.value.length > 20 ){
      Validate.inputWarn(pwinput.id, "* Enter 6 to 20 characters")
      Scroll.warnItems.push(pwinput.id)
      validated = false
    } else {  
      const lnStatus = Validate.validator('password', pwinput.value)
      if(lnStatus == false){
        Validate.inputWarn(pwinput.id, "* Only letters, numbers and !@#$%^&* allowed")
        Scroll.warnItems.push(pwinput.id)
        validated = false
      }
    }
    let stateField = false
    let thisCountry = null
    // country and state (if)
    if(coinput.value.includes('Select')){
      Validate.inputWarn(coinput.id, "*Selection required")
      Scroll.warnItems.push(coinput.id)
      validated = false
    } else { 
      let myCountry = Country.allCountries.find(field => field.countryName == coinput.value )
      thisCountry = myCountry
      if(myCountry.state){
        stateField = true
        if(steinput.value.includes('Select')){
          Validate.inputWarn(steinput.id, "*Selection required")
          Scroll.warnItems.push(steinput.id)
          validated = false
        }
      }
    }

    if(tfninput == ''){
      Validate.inputWarn(tfninput.id, "* Required")
      Scroll.warnItems.push(tfninput.id)
      validated = false
    } else {  
      const fnStatus = Validate.validator('tfn', tfninput.value)
      if(fnStatus == false){
        Validate.inputWarn(tfninput.id, "* Numbers only")
        Scroll.warnItems.push(tfninput.id)
        validated = false
      }
    }

    const dobYear = new Date(dobinput.value).getFullYear();
    const thisYear = new Date().getFullYear()
    if(dobYear > thisYear - 15){
      Validate.inputWarn(dobinput.id, "* Younger than 15")
      Scroll.warnItems.push(dobinput.id)
      validated = false
    }

    //default - no need for validation...
    const staffGroupInput = document.getElementById("staffgroup")
    const wageRateInput = document.getElementById("wageRate")
    const accessLevelInput = document.getElementById("accessLevel")
    
    if(validated == false){
      //if NOT scroll and return false

      return false //until the validation is put in place
    }
    console.log('validated successful... populating user data')


    const user = {}

    user.firstName = firstNameInput.value
    user.lastName = lastNameInput.value
    user.email = emailInput.value
    user.password = pwinput.value
    user.flagStatus = thisCountry.flag
    user.countryCode = thisCountry.countryCode
    if(stateField == true){ user.state = steinput.value }
    user.dob = dobinput.value
    user.tfn = tfninput.value
    user.accessLevel = accessLevelInput.value
    user.wageRate = wageRateInput.value
    user.staffGroup = staffGroupInput.value

    this.userData.user = user

    return true //until the validation is put in place

  }


  //{"user":{"firstName":"Peta","lastName":"Stewart","email":"ummraheil@gmail.com","password":"543454545","flagStatus":true,"countryCode":"aus","state":"Western Australia","dob":"2003-06-01","tfn":"4535454545","accessLevel":"5","wageRate":"1","staffGroup":"lily"}}


  valSelect(){
    //get the child input element id
    //pass it into validate below
    Validate.removeWarn('staffgroup')
  }


  //newmod successSource newmod

  // DATE PICKER - SETTING AS JAVASCRIPT
  // <label for="date-object">Date Object</label>
  // <input type="date" id="date-object" />

  // const input = document.querySelector('#date-object');
  // input.valueAsDate = new Date('2021-03-31'); // set using Date objects

  // input.addEventListener('input', () => {
  //   console.log(input.valueAsDate); // Tue Mar 16 2021 19:00:00 GMT-0500 (Central Daylight Time)
  // });

  render(){
    const template = html`
      <div class="main-content">
        <div class="content-panel">    
          <main-app-header pagename="${document.title}"></main-app-header>
          <div id="main-tab" >

            <div class="input-group">
              <label class="para label">First name:</label>
              <p id="first-name-wrn" class="input-require">* First name required</p>
              <input id="first-name" class="para" placeholder="First name ..." @keyup=${Validate.clearWarning.bind(Validate)}>
            </div>

            <div class="input-group">
              <label class="para label">Last name:</label>
              <p id="last-name-wrn" class="input-require">* Last name required</p>
              <input id="last-name" class="para" placeholder="Last name ..." @keyup=${Validate.clearWarning.bind(Validate)}>
            </div>

            <div class="input-group">
              <label class="para label">Email</label>
              <p id="user-email-wrn" class="input-require">* Valid email required</p>
              <input id="user-email" class="para" name="email" type="email" @keyup=${Validate.clearWarning.bind(Validate)} placeholder="Email"/>
            </div>

            <div class="input-group">
              <label id="freq-label" class="para label">Password:</label>
              <p id="password-wrn" class="input-require">* Password required</p>
              <input id="password" class="para" type="password" placeholder="Password" @keyup=${Validate.clearWarning.bind(Validate)} maxlength="20">
            </div>

            <div class="input-group">
              <label class="para label">Country</label>
              <p id="caseCountry-wrn" class="input-require">* Selection required</p>
              <div class="msc-wrap" @click="${Select.mcsOpenMenu.bind(Select)}">
                <select title="Country" id="caseCountry" class="para msc-input" type="text" name="caseCountry" @mousedown="${Select.preventSelect}">
                  <option disabled selected value="Select">Select country ...</option>
                </select>
              </div>
              <div class="msc-wrap hidden">
                <div class="msc-list-box">
                  <ul id="caseCountry-l" class="msc-ul para">
                  ${Country.priority.map(country => html `
                    <li><p @click="${Select.mcsSelectOption.bind(Select)}">${country.countryName}</p></li>
                    `)}
                    <div class="priority"></div>
                  ${Country.allCountries.map(country => html `
                      <li><p @click="${Select.mcsSelectOption.bind(Select)}">${country.countryName}</p></li>
                    `)}
                  </ul> 
                </div>
              </div>
            </div>

            <div id="states" class="input-group hidden">
              <label class="para label">State</label>
              <p id="caseState-wrn" class="input-require">* Selection required</p>
              <div class="msc-wrap" @click="${Select.mcsOpenMenu.bind(Select)}">
                <select title="State" id="caseState" class="para msc-input" type="text" name="caseState" @mousedown="${Select.preventSelect}">
                  <option disabled selected value="Select">Select state ...</option>
                </select>
              </div>
              <div class="msc-wrap hidden">
                <div class="msc-list-box">
                  <ul id="caseState-l" class="msc-ul para">

                  </ul> 
                </div>
              </div>
            </div>





            <div class="input-group">
              <label class="para label">Tax File Number</label>
              <p id="tfn-wrn" class="input-require">* Valid tax file number required</p>
              <input id="tfn" class="para" name="tfn" type="number" @keyup=${Validate.clearWarning.bind(Validate)} placeholder="Tax File Number"/>
            </div>

            <div class="input-group">
              <label class="para label">Date of Birth</label>
              <p id="dob-wrn" class="input-require">* Valid Date of Birth required</p>
              <input id="dob" class="para" name="dob" type="date" @keyup=${Validate.clearWarning.bind(Validate)} value="2003-06-01"/>
            </div>

            <div class="input-group">
              <label class="para label">Staff group:</label>
              <p id="staffgroup-wrn" class="input-require">* Selection required</p> 
              <div class="msc-wrap" @click="${this.valSelect.bind(Validate)}">
                <select title="Staff group" id="staffgroup" class="para" type="text" name="staffgroup">
                  <option selected value="lily">Lily</option>
                  <option value="admin">Admin</option>
                </select>
              </div>
            </div>

            <div class="input-group">
              <label class="para label">Wage Rate:</label>
              <p id="wageRate-wrn" class="input-require">* Selection required</p> 
              <div class="msc-wrap" @click="${this.valSelect.bind(Validate)}">
                <select title="Wage Rate" id="wageRate" class="para" type="number" name="wageRate">
                  <option selected value=1>1</option>
                  <option value=2>2</option>
                </select>
              </div>
            </div>

            <div class="input-group">
              <label class="para label">Access level:</label>
              <p id="accessLevel-wrn" class="input-require">* Selection required</p> 
              <div class="msc-wrap" @click="${this.valSelect.bind(Validate)}">
                <select title="Access Level" id="accessLevel" class="para" type="number" name="accessLevel">
                  <option selected value=5>5</option>
                  <option value=6>6</option>
                </select>
              </div>
            </div>

            <button class="submit-btn" @click=${this.andModHandler.bind(this)}>Register</button>

          </div>
          <div id="status-tab"></div>

        </div>  
      </div> 
    `

    render(template, App.rootEl)
  }
}

export default new NewmodView()