import App from '../../../App'
import {render, html} from 'lit/html.js'
import {anchorRoute } from '../../../Router'
import Validate from '../../../functions/Validate'
import SSign from '../../../SSign'
import {myLogo} from '../../../data/Images'
import Font from '../../../functions/Font'
//${myLogo}

//Version 2 - add the event listener to active events array via Events
class SigninView {
  init(){    
    document.title = 'Signin'  
    this.render()  
    Font.initFont() 
    document.addEventListener('keyup', SSign.signEnter) 
    this.addImages()
  }

  //this works but the sizing CSS needs revising
  //<div id="logo" class="signinup-logo-mobile logo-home" alt="${App.name}"></div>
  addImages(){
    const logoDiv = document.getElementById('logo')
    logoDiv.innerHTML = myLogo
  }

  viewForgot(){
    const formTitle = document.getElementById('frm-title')
    const toggleView = document.querySelectorAll('#sign-div, #forgot-div')
    formTitle.innerHTML = "Forgot Password"
    toggleView.forEach(view => { 
      view.classList.toggle('hidden')
    })
  }
  

  viewSign(){
    const formTitle = document.getElementById('frm-title')
    formTitle.innerHTML = "Sign In"
    const toggleView = document.querySelectorAll('#sign-div, #forgot-div')
    toggleView.forEach(view => { 
      view.classList.toggle('hidden')
    })
  }

  render(){
    const template = html`
      
      <div class="sign-content v-center">
        <div class="form-box"> 
          <div id="main-tab">
            <div class="signinup-logo-mobile">
              <div class="logo-home" id="logo" alt="logo ${App.name}"></div>
              <img  alt="${App.name}" src="https://hashtag-metoo.com/images/icons/2-apptext-${App.appcode}.svg" >
            </div>
            <p id="frm-title" class="form-name">Sign In</p>      
        
            <p id="user-email-wrn" class="input-require">*Need a height</p>
            <div class="input-group">
              <input id="user-email" class="para" type="email" placeholder="Email"  @keyup=${Validate.clearWarning.bind(Validate)}  required/>
            </div>

            <div id="sign-div">
              <p id="password-wrn" class="input-require">*Need a height</p>
              <div class="input-group">
                <input id="password" class="para" type="password" placeholder="Password"  @keyup=${Validate.clearWarning.bind(Validate)}  required>
              </div>
              <button class="submit-btn" submit @click=${SSign.signInSubmitHandler.bind(SSign)}>Sign in</button>
              <br>
              <p> <a href="#" class="sign-a-link" @click=${this.viewForgot}>Forgot password?</a></p>
            </div>

            <div id="forgot-div" class="hidden">
              <button class="submit-btn" submit @click=${SSign.sendLinkHandler.bind(SSign)}>Send Reset Link</button>
              <br><br>
              <p> <a href="#" class="sign-a-link" @click=${this.viewSign}>Back to Sign In</a></p>
            </div>
            <br>
            Developed by <a href="https://hashtag-webapp.com" target="_blank"> #WEBAPP </a> 

          </div>
          <div id="status-tab"></div>
        </div>
      </div>  
    `

    render(template, App.rootEl)
  }
}

export default new SigninView()